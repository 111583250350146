import { createStore } from 'vuex'
import user from "./modules/user";
import pictures from "./modules/pictures";
import config from "../config";
import season from "@/store/modules/season";
import clicker from "@/store/modules/clicker";

// Create a new store instance.
export default createStore({
    modules: {
        user,
        pictures,
        season,
        clicker
    },
    state() {
        return {
            mintProcessing: false,
            contractLoaded: false,
            loaders: {},
            localStorageWallet: {},
            tooltip:null,
            dataLoaded:{},
            dataLoadedCached:{},
        }
    },
    getters: {

        mintProcessing: state => state.mintProcessing,

        getLocalStorage: state => (key, def = false) => {
            let items = state.localStorageWallet;
            if (typeof items[key] === "undefined" || items[key] === null) return def;
            return items[key];
        },
        setLocalStorage: (state, getters) => (key, value) => {
            state.localStorageWallet[key] = value;
            localStorage[getters.wallet] = JSON.stringify(state.localStorageWallet)
        },
        contractLoaded: state => state.contractLoaded,
        isLoading: state => type => state.loaders[type] === undefined ? true : state.loaders[type],
        isLoadingBrut: state => type => state.loaders[type] || false,

        tooltip: state => state.tooltip,
        dataIsLoaded: state => name => {
            if(state.dataLoadedCached[name]){
                if(Date.now() > state.dataLoadedCached[name]){
                    return false
                }
            }
            return state.dataLoaded[name]
        }
    },
    actions: {
        async TOGGLE_LOADER({ commit }, { type, toggle }) {
            commit("TOGGLE_LOADER", { type, toggle })
        },
        async TOGGLE_LOADER_FORCE({ commit }, { type, toggle }) {
            commit("TOGGLE_LOADER_FORCE", { type, toggle })
        },
        async REFRESH_GLOBALS({ commit, getters }) {

            let localStorageWallet = {}

            if (getters.isConnected) {

                if (typeof localStorage[getters.wallet] !== "undefined" && localStorage[getters.wallet] !== null) {
                    if (JSON.parse(localStorage[getters.wallet]) === false) {
                        localStorageWallet = {}
                    }
                    localStorageWallet = JSON.parse(localStorage[getters.wallet])
                }
            }

            commit('REFRESH_GLOBALS', {
                getters,
                localStorageWallet,
            })
        },
        SHOW_TOOLTIP({commit}, tooltip) {
            commit("SHOW_TOOLTIP", tooltip)
        },
        HIDE_TOOLTIP({commit}, tooltip) {
            commit("HIDE_TOOLTIP", tooltip)
        },
        DATA_LOADED({commit}, {name, toggle}) {
            commit("DATA_LOADED",  {name, toggle})
        }
    },
    mutations: {
        CONTRACT_LOADED(state) {
            state.contractLoaded = true;
        },
        TOGGLE_LOADER: (state, { type, toggle }) => {
            if (state.loaders[type] === false) return
            state.loaders[type] = toggle;
        },
        TOGGLE_LOADER_FORCE: (state, { type, toggle }) => {
            state.loaders[type] = toggle;
        },
        REFRESH_GLOBALS: (state, data) => {

            for (const [key, value] of Object.entries(data)) {
                if (key === "getters") continue;
                state[key] = value;
            }

            console.log(state)
        },
        SHOW_TOOLTIP:(state, tooltip) => {
            state.tooltip = tooltip
        },
        HIDE_TOOLTIP:(state) => {
            state.tooltip = null
        },
        DATA_LOADED:(state, {name, toggle}) => {
            state.dataLoadedCached[name] = Date.now() + config.dataCached
            state.dataLoaded[name] = toggle
        }
    }
})