<template>
  <section id='mint' class='container'>
    <div class='wanna-wrapper line-height'>
      <div class='wanna'>
        The season is not started
      </div>
      <div class="bottom">
        <a class="cta" href="/">
          Back to Home
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotStarted"
}
</script>
