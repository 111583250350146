<template>
  <div class="countdown row" v-if="countdown >= 0">
    <div class="category">
      <div class='number'>{{ timer.days }}</div>
      <div class='label'>days</div>
    </div>
    <div class='spacer'>:</div>
    <div class="category">
      <div class='number'>{{ timer.hours }}</div>
      <div class='label'>hours</div>
    </div>
    <div class='spacer'>:</div>
    <div class="category">
      <div class='number'>{{ timer.minutes }}</div>
      <div class='label'>minutes</div>
    </div>
    <div class='spacer'>:</div>
    <div class="category">
      <div class='number'>{{ timer.seconds }}</div>
      <div class='label'>seconds</div>
    </div>
  </div>
</template>


<script>

import sounds from "@/class/sounds";

export default {
  name: "countdown-chest",
  props: {
    end: String,
    beforeEndDelay: Number,
    text: String,
    days: Boolean,
    after: Object,
  },
  data() {
    let countdown = this.calculationCountdown()
    let timer = this.explodeSeconds(countdown)
    return {
      countdown,
      timer,
    }
  },
  methods: {
    calculationCountdown() {
      return Math.round((new Date(this.end).getTime() / 1000) - (new Date().getTime() / 1000)) + 1
    },
    run() {
      this.interval = setInterval(() => {
        this.countdown = this.calculationCountdown();
        if (this.beforeEndDelay > 0) {
          if (this.countdown <= this.beforeEndDelay) {
            this.$emit('end');
            clearInterval(this.interval)
            this.countdown = -1;
            return
          }
        }
        if (this.countdown < 0) {
          clearInterval(this.interval)
          this.$emit('end')
          return
        }
        const timer = this.explodeSeconds(this.countdown);
        this.timer = {...this.timer, ...timer}
        this.$emit("running", this.time)

        if(this.timer.hours === "00" && this.timer.minutes === "00" && this.timer.seconds === "00"){
          sounds.play("click-nice.mp3");
        }else if(this.timer.minutes === "00" && this.timer.seconds === "00"){
          sounds.play("jar.mp3");
        }else if(this.timer.seconds === "00"){
          sounds.play("click.mp3");
        }else{
          sounds.play("beep.mp3");
        }

      }, 1000)
    },
    explodeSeconds(seconds) {
      let days = Math.floor(seconds / 24 / 60 / 60);
      let hoursLeft = Math.floor((seconds) - (days * 86400));
      let hours = Math.floor(hoursLeft / 3600);
      let minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
      let minutes = Math.floor(minutesLeft / 60);
      let remainingSeconds = seconds % 60;

      return {
        days: days,
        hours: this.pad(hours),
        minutes: this.pad(minutes),
        seconds: this.pad(remainingSeconds)
      }
    },
    pad(n) {
      return (n < 10 ? "0" + n : n);
    }
  },
  computed: {
    afterData() {
      if (!this.after) {
        return {
          days: "",
          hours: "",
          minutes: "",
          seconds: "",
        }
      }
      return this.after
    }
  },
  watch: {
    end() {
      this.countdown = this.calculationCountdown();
      this.timer = this.explodeSeconds(this.countdown)
      if (this.countdown >= 0) {
        clearInterval(this.interval)
        this.run();
      } else {
        this.$emit('end')
        clearInterval(this.interval)
      }
    }
  },
  mounted() {
    this.run()
  },
  unmounted() {
    clearInterval(this.interval)
  }
}
</script>
