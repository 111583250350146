<template>
  <div class='container column'>
    <div class='title full'>
      <div class='left'>
        <h1><span class='green'>Pickles</span><br/>run away!</h1>
        <h2>
          <span v-if="!day.isFinal()">Day {{ day.getDayId() + 1 }}/6</span>
          <span v-else>FINAL</span>
        </h2>
      </div>
      <countdown
          :end="season.getProcessingDayTime(day.getDayId())"
          :after="{hours:':',minutes:':'}"
          @end="chosenClose = true"
      />
      <div class="countdown" v-if="chosenClose">
        <loader text="Closing"/>
      </div>
    </div>

    <Booster  v-if="increaseChance && !chosenClose" @close="increaseChance = false" :day="day" :season="season"/>

    <Popup v-if="popupSpyResult && spyResult" :content="popupSpyResult" @close="popupSpyResult = null" class="modal-spy">
      <div class="content selection">
        <p>
          Crawling like a professional pickle spy, {{ new Date(spyResult.date).toLocaleString() }}, you can count {{ spyResult.result }} {{ $filters.plural('pickle', spyResult.result) }} in this path!
        </p>
        <div class="wrapper-select">
          <div
              class='paths-list'
          >
            <div class='wrapper-select row'>
              <div class='path' style="pointer-events: none">
                <img :src='$store.getters.picture(day.getWayPictureURL(day.getSpyResult().wayId, false))' alt=''>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>

    <div class='selection column'>
      <div class='boost-wrapper row'>
        <h5 v-if="hasPickle && season.isPlayer() && !day.isFinal()">
          Choose a path for your <span class='green'>{{ day.getUserPickleAlive() }} {{ $filters.plural('pickle',day.getUserPickleAlive() ) }}</span> to run away.
        </h5>
        <h5 v-if="hasPickle && season.isPlayer() && day.isFinal()">
          Choose a plane for your <span class='green'>{{ day.getUserPickleAlive() }} {{ $filters.plural('pickle',day.getUserPickleAlive() ) }}</span> to fly away.<br/>
          You have 24 hours to choose the right plane and have a chance to escape and be the last standing pickle!
        </h5>
        <h5 v-if="!season.isPlayer()">
          You are in spectator mode<br/>
          You are out of the game but you can still support your friends and help them using Discord!
        </h5>
        <h5 v-if="season.isPlayer() && !hasPickle">
          You are in spectator mode
        </h5>
        <div class='boost row'>
          <div class='row'>
            <img src='/images/boost/boost_1.jpg' alt='Boost 01' v-if="day.getBonus(1)" title="Shield">
            <img src='/images/boost/boost_3.jpg' alt='Boost 01' v-if="day.getBonus(3)" title="Spy" @click.prevent.stop="openSpyResult" style="cursor: pointer">
          </div>
          <div class='cta secondary' @click.prevent.stop="openIncreaseChance" v-if="season.getUserHasJar() && !day.isFinal() && season.isPlayer() && hasPickle && !chosenClose">Tools</div>
        </div>
      </div>
      <div
          class='pathslist'
          ref="scrollable"
          @mousedown.prevent.stop="startScroll"
          @mouseup.prevent.stop="stopScroll"
          @mousemove="scroll"
          @wheel.prevent="scrollWheel"
          :class="{'scroll':scrollable.move,'smooth':scrollable.smooth}"
      >
        <div class='wrapper-path row' :class="{'not-player':!season.isPlayer() || !hasPickle || chosenClose, 'is-final':day.isFinal()}">
          <div
              v-for="(data) in ways"
              :key="data.wayId"
              class='path'
              :class="{
                'not-selected': season.isPlayer() && (!hasPickle || (isSelected(data.wayId) === false && isSelected(data.wayId) !== null)) || !this.season.isPlayer() || chosenClose,
                'selected':isSelected(data.wayId),
              }"
              @mouseup="choose(data.wayId)"
          >
            <img :src='$store.getters.picture(day.getPictureURL(data.pictureId))' alt='' v-if="!day.isFinal()">
            <div class="plane-path" v-if="day.isFinal()">
              <img :src='$store.getters.picture(day.getPictureURL(data.pictureId))' alt=''>
              <div class="planeId" v-if="day.isFinal()">#{{season.getUserJarId()}}</div>
            </div>
            <div class='wrapper-link'>
              <div class='link' v-if="!isSelected(data.wayId)">
                Select<span class='shorted'> this path</span>
              </div>
              <div class='link' v-else>
                Selected
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='informations row'>
        <h6><span class='green'>{{ pctPickles }}%</span> of the Pickles left ({{ day.getAlive() }})</h6>
        <h6><span class='green'>{{ pctChosen }}%</span> of the pickles have chosen a path</h6>
        <div class="cta" v-if="day.getDayId() > 0" @click.prevent.stop="daysDetail(day.getDayId() - 1)">
          Previous Days details
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from "@/components/Countdown";
import api from "@/class/api";
import contract from "@/class/contract";
import Loader from "@/components/Loader";
import Booster from "@/components/_popup/Booster";
import Popup from "@/components/Popup";

export default {
  name: "Choose",
  components: {Popup, Booster, Loader, Countdown},
  data:() => ({
    chosenClose:false,

    increaseChance:false,

    scrollable:{
      state:false,
      move:false,
      offset:0,
      origin:0,
      smooth:false
    },
    popupSpyResult:null
  }),
  methods: {
    startScroll($event){
      this.scrollable.state = true;
      this.scrollable.offset = $event.clientX;
      this.scrollable.origin = this.$refs.scrollable.scrollLeft;
    },
    stopScroll(){

      this.scrollable.state = false
      this.scrollable.move = false;
    },
    scroll($event){
      if(!this.scrollable.state){
        return;
      }

      this.scrollable.move = true;
      const x = (this.scrollable.offset - $event.clientX)
      this.$refs.scrollable.scrollLeft = this.scrollable.origin + x
    },
    scrollWheel($event){
      const x = $event.deltaY / 2
      this.$refs.scrollable.scrollLeft += x
    },
    openIncreaseChance(){
      this.increaseChance = true;
    },
    async choose(wayId) {

      if(this.scrollable.move){
        return;
      }

      if (!this.$store.getters.isConnected) {
        return this.emitter.emit("alert:error", "global_not_connected")
      }
      if (!this.hasPickle) {
        return this.emitter.emit("alert:error", "global_no_pickle")
      }
      if (!this.season.isPlayer()) {
        return this.emitter.emit("alert:error", "global_no_pickle")
      }
      if (this.isSelected(wayId)) {
        return this.emitter.emit("alert:info", "global_already_selected")
      }
      if (this.chosenClose) {
        return this.emitter.emit("alert:info", "global_chosen_close")
      }

      if(this.day.isFinal()){
        wayId += 1
      }

      const wallet = this.$store.getters.wallet
      const message = {
        owner: wallet,
        wayId: wayId
      }

      this.emitter.emit('alert:loader', "global_way_signing")

      let signature;
      try {
        signature = await contract.createSignature(message,wallet)
      } catch (e) {
        this.emitter.emit('alert:loader:hide')
        return this.emitter.emit("alert:error", "global_sign_error")
      }

      this.emitter.emit('alert:loader:hide')
      this.emitter.emit('alert:loader', "global_way_selection")

      const result = await api.post("game/way", {
        message,
        signature
      })

      if (result.error()) {
        this.emitter.emit('alert:loader:hide')
        return this.emitter.emit("alert:error", "global_error_toolate")
      }

      await this.$store.dispatch('UPDATE_SEASON')

      await new Promise(resolve => {
        setTimeout(() => {
          this.emitter.emit('alert:loader:hide')
          resolve()
        }, 1000)
      })

      this.emitter.emit("alert:success", "global_way_choose_valid")

      // this.scrollable.smooth = true;

      // setTimeout(() => {
      //   this.$refs.scrollable.scrollLeft = 0
      // },0)

      // setTimeout(() => {
      //   this.scrollable.smooth = false
      // },500)

    },
    isSelected(wayId){
      if(this.day.getUserSelectWayId() === -1){
        return null;
      }
      if(this.day.isFinal()){
        return wayId + 1 === this.day.getUserSelectWayId()
      }

      return wayId === this.day.getUserSelectWayId()

    },
    daysDetail(dayId){
      this.$root.showDetailsDayId = dayId
      this.$root.showDailyOpen = true;
    },
    openSpyResult(){
      this.popupSpyResult = {
        title: "Spy result",
        cancel: "Close",
        reject: () => {},
      }
    }
  },
  computed: {
    season() {
      return this.$store.getters.season;
    },
    day() {
      console.log(this.$store.getters.season.getCurrentDay())
      return this.$store.getters.season.getCurrentDay();
    },
    hasPickle() {
      return this.day.getUserPickleAlive() > 0
    },
    pctPickles() {
      if (this.season.getTotalSupply() === 0) {
        return 0
      }
      return (this.day.getAlive() / this.season.getTotalSupply() * 100).toFixed(2)
    },
    pctChosen() {
      if (this.day.getAlive() === 0) {
        return 0
      }
      return (this.day.getSelected() / this.day.getAlive() * 100).toFixed(2)
    },
    ways(){

      const pictures = this.day.getPictures()
      const ways = []
      for(let wayId = 0;wayId < pictures.length; wayId++){
        ways.push({wayId:wayId, pictureId:pictures[wayId]});
      }

      // ways.sort((w1) => {
      //   if(this.isSelected(w1.wayId)){
      //     return -1
      //   }
      //   return 1;
      // })

      return ways
    },
    spyResult(){
      return this.day.getSpyResult()
    }
  },
  watch:{
    spyResult(value, previous){
      if(value && previous == null){
        this.openSpyResult()
      }
    }
  }
}
</script>
