<template>
  <div class="content">
    <p style="margin-bottom: 30px">
      Choose the amount of pickles you want to put in your fucking jar!<br/><br/>
      Note: During the game, you will not be able to choose several paths and having a lot of pickles also means losing a lot!
    </p>
  </div>
</template>

<script>
import config from "@/config";

export default {
  name: "popup-set-pickle",
  data: () => ({
    checked:false,
    openSeaJarCollection: config.openSeaJarCollection
  }),
  watch: {
    checked() {
      this.$emit('valid', this.checked)
    }
  },
  mounted() {
    this.$emit('valid', true)
  }
}
</script>
