<template>
  <section id='mint' class='container'>
    <div class='wanna-wrapper line-height'>
      <div class='wanna'>
        Error<br/>please reload the page
      </div>
      <div class="bottom">
        <a class="cta" href="#game">
          Reload
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Error"
}
</script>
