<template>

  <div class='container column'>
    <!-- <div class='title'>
      <div class='left'>
        <h1>Last night<br/><span class='red'>was bloody</span></h1>
        <h2>Day 2/6</h2>
      </div>
      <div class='countdown'>It’s time</div>
    </div> -->

    <div class='selection dailyresults row'>
      <div class='left order-first column'>
        <div class='title'>
          <div class='left' v-if="!isDead">
            <h1>Last day<br/><span class='red'>was bloody</span></h1>
            <h2>Day {{ day.getDayId() + 1 }}/6</h2>
          </div>
          <div class='left' v-if="isDead">
            <h1>All of your pickles<br/><span class='red'>ARE DEAD</span></h1>
            <h2>Day {{ day.getDayId() + 1 }}/6</h2>
          </div>
        </div>
        <div v-if="isFullLoaded">
          <div class='result-line' v-if="isAlive">
            You lost
            <span :class='day.getUserPickleDead() > 0 ? "red" : "green" '>
            {{ $filters.number(day.getUserPickleDead()) }} {{ $filters.plural('pickle', day.getUserPickleDead()) }}
          </span> yesterday.
          </div>
          <div class='result-line' v-if="!isDead && !isSpectator">
            You still have
            <span class='green'>
            {{
                $filters.number(day.getUserPickleAlive() - day.getUserPickleDead())
              }} {{ $filters.plural('pickle', day.getUserPickleAlive() - day.getUserPickleDead()) }}
          </span> left.
          </div>
          <div class='result-line' v-if="isDead">
            All your pickles are <span class='red'>dead</span>!
          </div>
          <div class='result-line' v-if="isDead">
            You finished <span class="green">#{{ season.getRecap().rank }}</span>! <span v-if="season.getFinal().getUserReward()">And you won {{
              $filters.fromWei(season.getFinal().getUserReward().reward)
            }}</span>
          </div>
          <div class='result-line claim' v-if="isDead">
            Congratulation Mr Pickle! Better luck next time!
          </div>
          <div class='result-line claim' v-if="season.getFinal().getUserReward()">
            You will be able to claim your prize in few hours.
          </div>
          <div class='result-line claim' v-if="isDead">
            Season end in
            <countdown
                :surchargeClass="'countdown-small'"
                :end="season.getNextSeasonTime()"
                :days="true"
                :after="{days:'d ', hours:'h ', minutes:'m ', seconds:'s'}"
            />
          </div>
          <div class='global'>
            <div class='result-line'>
              <span class='grey'>
                {{ day.getSelectBadWay() }}
              </span>
              {{ $filters.plural('pickle', day.getSelectBadWay()) }} selected the wrong path.
            </div>
            <div class='result-line'>
              <span class='red'>
                {{ $filters.number(day.getWayDead().getBalance()) }}
              </span>
              {{ $filters.plural('pickle', day.getWayDead().getKilled()) }} didn't chose a path,
              <span class='red'>
                {{ $filters.number(day.getWayDead().getKilled()) }}
              </span>
              {{ $filters.plural('pickle', day.getWayDead().getKilled()) }} died!.
            </div>
            <div class='result-line'>
              <span class='grey'>
                {{ $filters.number(day.getKilled()) }}
              </span>
              {{ $filters.plural('pickle', day.getKilled()) }} died yesterday.
            </div>
            <div class='result-line'>
              <span class='grey'>
                {{ $filters.number(day.getAlive() - day.getKilled()) }}
              </span>
              {{ $filters.plural('pickle', day.getAlive() - day.getKilled()) }} left.
            </div>
          </div>
        </div>
        <div v-else>
          <div class='result-line' style="margin-bottom: 50px">
            <loader />
          </div>
        </div>
        <div class='overview death column'>
          <div class='overview-left'></div>
          <div class='wrapper-border column'>
            <div class='top row'>
              <div class='overview-title'>Daily Overview</div>
              <div class='border-top'></div>
            </div>
            <div class='list row'>
              <div
                  class='day'
                  v-for="(type, _dayId) in season.getUserPath()"
                  :key="_dayId"
                  :class="[type, {'selected': _dayId === day.getDayId()}]"
                  @click.prevent.stop="changeDay(_dayId, type)"
              ></div>
            </div>
          </div>
          <div class='overview-right'></div>
        </div>

        <div class='pushs row'>
          <div class='cta' v-if="!nextDayLoader && !$root.showDailyOpen" @click.prevent.stop="nextDay">
            <span v-if="season.isDead()">Continue in spectator</span>
            <span v-else>Next day</span>
          </div>
          <div class='cta' v-if="nextDayLoader">
            <loader text="Loading"/>
          </div>
          <div class='cta' v-if="$root.showDailyOpen" @click.prevent.stop="$root.showDailyOpen = false">
            Back
          </div>
          <a href="/" class='cta secondary'>
            Back to home
          </a>
        </div>
      </div>
      <div class='right row'>
        <PathStats
            v-for="(way, k) in day.getWaysAlive()"
            :key="k"
            :day="day"
            :way="way"
            :winner="day.getWinnerWayId() === way.getWayId()"
            :selected="isSelected(way.getWayId())"
        />
      </div>
      <Diapo
        v-if="$root.showDiapo"
        :skip="true"
        :steps="stepImage"
        @skipped="diapoClose"
      />
    </div>
  </div>
</template>

<script>

import PathStats from "@/components/PathStats";
import Loader from "@/components/Loader";
import api from "@/class/api";
import Countdown from "@/components/Countdown";
import Diapo from "@/components/Diapo";

export default {
  components: {Diapo, Countdown, Loader, PathStats},
  data: () => ({
    nextDayLoader: false,
    forceLoaded: false,
  }),
  methods: {
    changeDay(dayId, type) {
      if (type === "notyet") {
        return
      }
      if(dayId === 5){
        this.$root.showDetailsDayId = -1
        this.$root.showDailyOpen = false;
        this.$root.finalRecap = this.season.getSeasonId()
        return;
      }
      this.$root.showDetailsDayId = dayId
    },
    isSelected(wayId) {
      if (this.day.getUserSelectWayId() === -1) {
        return null;
      }
      return wayId === this.day.getUserSelectWayId()
    },
    async nextDay() {
      if(this.day.getDayId() === 0 || this.day.getDayId() >= 4) {
        this.$root.showDiapo = true;
      }else{
        await this.diapoClose()
      }
    },
    async diapoClose(){

      this.nextDayLoader = true

      const result = await api.post("game/user/" + this.$store.getters.wallet + "/valid-death-screen", {
        seasonId: this.season.getSeasonId(),
        dayId: this.season.getPrevDay().getDayId()
      })

      if (result.error()) {
        this.nextDayLoader = false
        return this.emitter.emit("alert:error", "global_error_server")
      }

      await this.$store.dispatch("UPDATE_SEASON")

      this.$root.showDetailsDayId = -1
      this.$root.showDailyOpen = false
      this.$root.showDiapo = false
      this.nextDayLoader = false
    }
  },
  computed: {
    season() {
      return this.$store.getters.season;
    },
    day() {
      let dayId = this.$root.showDetailsDayId;
      if (dayId === -1) {
        dayId = this.season.getPrevDay().getDayId()
      }
      return this.season.getDay(dayId)
    },
    isSpectator(){
      return !this.season.isPlayer() || this.season.isPlayer() && this.isFinish
    },
    isFinish() {
      return this.day.getUserPickleAlive() - this.day.getUserPickleDead() === 0
    },
    isAlive() {
      return this.season.isPlayer() && this.day.getUserPickleAlive() > 0
    },
    isDead() {
      return this.season.isPlayer() && this.day.getUserPickleAlive() > 0 && this.isFinish
    },
    isFullLoaded() {
      return this.day.getKilled() > 0 || this.forceLoaded === true
    },
    stepImage() {
      console.log(this.season.getCurrentDay().getDayId())
      if (this.season.getCurrentDay().getDayId() < 4) {
        return [
          {image: 'step/step_45.jpg', title: 'New night, new party!', text: 'The queue is still very long, but for nothing in the world the pickles would miss this party!',},
          {image: 'step/step_60.jpg', title: 'Party Time! Again!', text: 'Best Party Ever... Nothing more...'},
          {image: 'step/step_70.jpg', title: 'Busted!', text: 'The one they call “human”, reaches for the light switch after being awoken by an odd sound coming from the kitchen.\n',},
          {image: 'step/step_80.jpg', title: 'Party’s over boys', text: 'It was chaos, the Pickles were panicking, screaming and feared for what was coming for them.',},
          {image: 'step/step_100.jpg', title: 'The trials start again..', text: 'The pickles were now facing death head on, one wrong move and it could all be over.\n' +
                ' Choose your path wisely, your life depends on it.',}
        ]
      }
      return [
        {image: 'step/step_110.jpg', title: 'In the antechamber', text: 'This is it... The last fucking day. Golden Pickle’s party is going to be insane!' +
              '<br/>The remaining pickles that are attending this last party are some tough mfers!<br/>The chaos is unstoppable!'},
        {image: 'step/step_120.jpg', title: 'I believe I can fly', text: 'Stuck on top of the fridge, the pickles have no other choice. They have to fly to the window.',}
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      this.forceLoaded = true;
    }, 20000)
  }
}
</script>
