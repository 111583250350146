import contract from "./class/contract";

export default {
    number(value, digits) {
        if (typeof value === "string") {
            value = parseFloat(value)
        }
        let val = value
        if (!digits && digits !== 0) {
            if (value < 0) {
                val = value.toFixed(5)
            } else {
                val = value.toFixed(3)
            }
        } else {
            val = value.toFixed(digits)
        }
        let data = val.toString().split('.')
        data[0] = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (data[0].length > 4 || data[1] === "000") data = [data[0]]
        if (data[1] && data[1].length > 2 && data[1][2] === "0") data[1] = data[1][0] + "" + data[1][1]
        return data.join(".")
    },
    addressSub(value, small = false) {
        if (!value) return "";
        if (window.innerWidth <= 890 || small) {
            return value.substr(0, 6) + "..."
        }
        return value.substr(0, 6) + "..." + value.substr(value.length - 4, 4)
    },
    plural(string, number) {
        return number > 1 ? string + 's' : string;
    },
    ownerNickname(owner) {
        if (!owner.nickname) {
            return this.addressSub(owner.address);
        }

        return owner.nickname
    },
    ocFirst(name) {
        return name[0].toUpperCase() + "" + name.slice(1)
    },
    fromWei(number) {
        return this.number(parseFloat(contract.fromWei(number)))
    },
    limit(text, limit){
        if(text.length <= limit) return text;
        return text.substr(0,limit - 3)+"..."
    }
}