<template>
  <div :class="surchargeClass ? surchargeClass : 'countdown'" v-if="countdown >= 0">
    <div class="content">
      <div class="text" v-if="text && text !== ''">
        {{ text }}
      </div>
      <div class="time">
        <div class="days" v-if="days">
          <span>{{ timer.days }}</span>{{ afterData.days }}
        </div>
        <div class="hours">
          <span>{{ timer.hours }}</span>{{ afterData.hours }}
        </div>
        <div class="minutes">
          <span>{{ timer.minutes }}</span>{{ afterData.minutes }}
        </div>
        <div class="seconds">
          <span>{{ timer.seconds }}</span>{{ afterData.seconds }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Countdown",
  props: {
    end: String,
    beforeEndDelay: Number,
    text: String,
    days: Boolean,
    after: Object,
    surchargeClass:String,
  },
  data() {
    let countdown = this.calculationCountdown()
    let timer = this.explodeSeconds(countdown)
    return {
      countdown,
      timer,
    }
  },
  methods: {
    calculationCountdown() {
      return Math.round((new Date(this.end).getTime() / 1000) - (new Date().getTime() / 1000)) + 1
    },
    run() {
      this.interval = setInterval(() => {
        this.countdown = this.calculationCountdown();
        if (this.beforeEndDelay > 0) {
          if (this.countdown <= this.beforeEndDelay) {
            this.$emit('end');
            clearInterval(this.interval)
            this.countdown = -1;
            return
          }
        }
        if (this.countdown < 0) {
          clearInterval(this.interval)
          this.$emit('end')
          return
        }
        const timer = this.explodeSeconds(this.countdown);
        this.timer = {...this.timer, ...timer}
        this.$emit("running")
      }, 1000)
    },
    explodeSeconds(seconds) {
      let days = Math.floor(seconds / 24 / 60 / 60);
      let hoursLeft = Math.floor((seconds) - (days * 86400));
      let hours = Math.floor(hoursLeft / 3600);
      let minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
      let minutes = Math.floor(minutesLeft / 60);
      let remainingSeconds = seconds % 60;

      return {
        days: days,
        hours: this.pad(hours),
        minutes: this.pad(minutes),
        seconds: this.pad(remainingSeconds)
      }
    },
    pad(n) {
      return (n < 10 ? "0" + n : n);
    }
  },
  computed: {
    afterData() {
      if (!this.after) {
        return {
          days: "",
          hours: "",
          minutes: "",
          seconds: "",
        }
      }
      return this.after
    }
  },
  watch: {
    end() {
      this.countdown = this.calculationCountdown();
      this.timer = this.explodeSeconds(this.countdown)
      if (this.countdown >= 0) {
        clearInterval(this.interval)
        this.run();
      } else {
        this.$emit('end')
        clearInterval(this.interval)
      }
    }
  },
  mounted() {
    if (this.countdown >= 0) {
      clearInterval(this.interval)
      this.run();
    } else {
      this.$emit('end')
      clearInterval(this.interval)
    }
  }
}
</script>