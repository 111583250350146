<template>
  <section id='clicker' class='container' :class="{opening, ended, startTransition}">
    <div class='smallScreen logo-box'>
      <div class='logo align_vertical'></div>
    </div>
    <div class="sounds">
      <img src="/images/sound-mute.svg" alt="" v-if="muted" @click.prevent.stop="mute(false)">
      <img src="/images/sound.svg" alt="" v-else @click.prevent.stop="mute(true)">
    </div>
    <div class='data canvas'>
      <div class="wallet-connect" v-if="!playerReady">
        <section id="mint">
          <div class='wanna-wrapper'>
            <div class='wanna'>
              Pickle Time
            </div>
            <div class="bottom" v-if="!$store.getters.isConnected">
              <div class="cta" @click.prevent.stop="connect" >
                Connect your wallet
              </div>
            </div>
            <div class="bottom" v-else>
              <div class="cta" @click.prevent.stop="playerReady = true" >
                SMASH THE CHEST!
              </div>
            </div>
          </div>
        </section>
      </div>
      <img class="bg_clicker" src='/images/clicker/background.jpg' rel="preload">
      <div class="float_element">
        <div :class="classesChest.concat(' ')">
          <img class="chest-img" src='/images/clicker/chest.png' rel="preload" @mouseout="unclikFront" @mousedown.stop.prevent="clickOnChestEvent" @mouseup.stop.prevent="unclikFront" @touchstart.stop.prevent="clickOnChestEvent" @touchend.stop.prevent="unclikFront"/>
          <img class="key_normal" :src="keyImageUrl" rel="preload">
        </div>
        <img class="pickle_one" :src="pickleUrlImageList[0]" rel="preload">
        <img class="pickle_two" :src="pickleUrlImageList[1]" rel="preload">
        <img class="pickle_three" :src="pickleUrlImageList[2]" rel="preload">
        <img class="pickle_four" :src="pickleUrlImageList[3]" rel="preload">
        <img class="floor" src='/images/clicker/floor.png' rel="preload">
      </div>
    </div>
    <div class="data details">
      <div class='logo-box'>
        <div class="align_vertical">
          <div class='logo'></div>
        </div>
      </div>
      <div class='durability-box'>
        <div class="align_vertical">
          <loader v-if="!serverReady" text=" " class="value"/>
          <counter :current="gameDetails.nbTotalRemainingClick" :duration="durationDelay" v-show="serverReady && !isGameFinished"/>
          <div class="value" v-show="isGameFinished">0</div>
          <div class="title">DURABILITY</div>
        </div>
        <div class="separator"></div>
      </div>
      <div class='myhits-box'>
        <div class="align_vertical">
          <loader v-if="!serverReady || !serverPlayerReady" text=" " class="value"/>
          <div class="value" v-show="serverReady && serverPlayerReady">{{ $tools.commify(gameDetails.nbTotalUserClick + tmpNbUserClick) }}</div>
          <div class="title">MY HITS</div>
        </div>
        <div class="separator"></div>
      </div>
      <div class='degens-box'>
        <div class="align_vertical">
          <loader v-if="!serverReady" text=" " class="value"/>
          <counter :current="gameDetails.nbTotalUser" :duration="durationDelay" v-show="serverReady"/>
          <div class="title">ONLINE DEGENS</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { tools } from "@/utils";
//import contract from "@/class/contract";
import api from "@/class/api";
import sounds from "@/class/sounds";
import Counter from "@/components/counter";
import md5 from "md5"
import Loader from "@/components/Loader";

const pickleSpeedAnimation = 100;
const chestKeySpeedAnimation = 50;
const intervalSendUserClicks = 5000;

const pickleUrlImageList = [
  '/images/clicker/pickle_01_normal.png',
  '/images/clicker/pickle_02_normal.png',
  '/images/clicker/pickle_03_normal.png',
  '/images/clicker/pickle_04_normal.png'
];

const imagesClicker = [
  "/images/clicker/background.jpg",
  "/images/clicker/chest.png",
  "/images/clicker/pickle_01_normal.png",
  "/images/clicker/pickle_01_hitting.png",
  "/images/clicker/pickle_02_normal.png",
  "/images/clicker/pickle_02_hitting.png",
  "/images/clicker/pickle_03_normal.png",
  "/images/clicker/pickle_03_hitting.png",
  "/images/clicker/pickle_04_normal.png",
  "/images/clicker/pickle_04_hitting.png",
];

const initialKeyImageUrl = "/images/clicker/key_normal.png";
const turnedKeyImageUrl = "/images/clicker/key_turned.png";

export default {
  name: "Clicker",
  components: {Loader, Counter},
  data:() => ({
    opening:false,
    ended:false,
    endedAnimation:false,
    startTransition:false,
    playerReady:false,
    serverReady:false,
    serverPlayerReady:false,
    muted:false,
    startDelay:Date.now(),
    durationDelay:5000,

    pickleUrlImageList,
    keyImageUrl: initialKeyImageUrl,
    classesChest: ["chest"],
    classesKey: ["key_normal"],
    tmpNbUserClick: 0,
    timer: null,
    gameDetails: {
      nbTotalUserClick: 0,
      nbTotalUser: 0,
      nbTotalRemainingClick: 0,
    },
    isGameFinished: false,
    pickleAnimated:{},
    keyDown:false
  }),
  methods:{
    connect(){
      this.emitter.emit("web3modal:connect")
    },
    _managePickleAnimation(pickleSpeedAnimation) {
      const randomPositionImgPickle = tools.random(0, this.pickleUrlImageList.length);

      if(this.pickleAnimated[randomPositionImgPickle] > 0){
        return;
      }

      this._hitEvent(randomPositionImgPickle + 1);

      this.pickleAnimated[randomPositionImgPickle] = Date.now() + pickleSpeedAnimation;

      this.pickleUrlImageList[randomPositionImgPickle] = `/images/clicker/pickle_0${(randomPositionImgPickle + 1)}_hitting.png`;

    },
    clearAnimation(){
      for(let i = 0; i < this.pickleUrlImageList.length; i++) {
        if (this.pickleAnimated[i] < Date.now() && this.pickleAnimated[i] > 0){
          this.pickleUrlImageList[i] = `/images/clicker/pickle_0${(i + 1)}_normal.png`;
          this.pickleAnimated[i] = 0
        }
      }
    },
    _manageChestAndKeyAnimation(chestKeySpeedAnimation) {
      this.classesChest.push('animation');
      this.classesKey.push('animation');

      setTimeout(() => {
        this.classesChest.pop();
        this.classesKey.pop();
      }, chestKeySpeedAnimation);
    },
    _runEventGameDetailsData(intervalSendUserClicks) {
      return setTimeout(this._manageUserGame, intervalSendUserClicks);
    },
    async _getGameDetails(nbClick) {

      const headers = {
        sign: this.$store.getters.wallet || "guest"
      }

      if (nbClick === 0) {
        return api.get(this.$config.envs.BACKEND_URI_CLICKER+'/clicker', { headers });
      }

      return api.post(this.$config.envs.BACKEND_URI_CLICKER+'/clicker', { nbClick }, { headers });
    },
    _updateGameDetails(payload) {
      this.gameDetails.nbTotalRemainingClick = payload.nbTotalRemainingClick;
      this.gameDetails.nbTotalUser = payload.nbUsers;

      if (payload.myClicks) {
        this.gameDetails.nbTotalUserClick = payload.myClicks;
        this.serverPlayerReady = true
      }

      this.serverReady = true
    },
    _hitEvent(val) {

      if (!val) {
        return;
      }

      let hit = tools.random(1,9);
      if (this.soundHit === hit) {
          hit++;
          if (hit > 9) hit = 1;
      }

      this.soundHit = hit;
      sounds.play("hit-" + hit + ".mp3");

      if(val === 4) {
        let sword = hit % 3 > 0 ? hit % 3 : 1;
        sounds.play("sword-" + sword + ".mp3");
      }
    },
    async _manageUserGame() {
      const result = await this._getGameDetails(this.tmpNbUserClick);
      this.durationDelay = Date.now() - this.startDelay
      this.startDelay = Date.now()

      this.tmpNbUserClick = 0;

      if (result.error()) {
        return this._runEventGameDetailsData(intervalSendUserClicks);
      }

      // result._data.payload = {hash: "true"}

      const payload = result.payload();
      let isEndOfGame = false;
      if(payload?.hash) {
        isEndOfGame = md5(payload?.hash).toLowerCase() === "77109cade33c1d9ca8c656bcf0ac355a";
      }

      if (isEndOfGame && !this.isGameFinished && this.playerReady) {
        this.isGameFinished = true;
        this.gameDetails.durationDelay = 0;
        this.gameDetails.nbTotalRemainingClick = 0;
        this.keyImageUrl = turnedKeyImageUrl;
        clearInterval(this.timer);
        await this._endGameAnimation()
        return;
      }

      this._updateGameDetails(payload);

      return this._runEventGameDetailsData(intervalSendUserClicks);
    },
    clickOnChestEvent() {
      if (this.isGameFinished) {
        return;
      }

      this.tmpNbUserClick += 1;
      this._managePickleAnimation(pickleSpeedAnimation);
      this._manageChestAndKeyAnimation(chestKeySpeedAnimation);
    },
    unclikFront() {
      // this._hitEvent(false);
    },
    smashKeyboard(){
      if(!this.playerReady){
        return
      }
      if(this.keyDown == true){
        return;
      }
      this.keyDown = true;
      this.clickOnChestEvent();
    },
    releaseKeyboard(){
      this.keyDown = false
    },
    async _endGameAnimation(){
      sounds.play("key-unlock.mp3");

      await this.$tools.sleep(2000)

      this.ended = true;

      await this.$tools.sleep(5000)

      this.startTransition = true;

      await this.$tools.sleep(5000)

      sounds.play("chest-open.mp3")

      this.$root.clickerEnd = true;
      this.$root.openingChest = true;
    },
    fakeSmash(){
      if(this.playerReady){
        clearTimeout(this.intervalFakeSmash)
        return;
      }

      this._managePickleAnimation(pickleSpeedAnimation)
      this._manageChestAndKeyAnimation()

      this.intervalFakeSmash = setTimeout(() => {
        this.fakeSmash();
      },Math.random() * 1000)
    },
    mute(toggle){
      sounds.mute(toggle)
      this.muted = toggle
      console.log(toggle, sounds.muted)
    }
  },
  async mounted () {
    this.timer = this._runEventGameDetailsData(0); // first time call to init game details

    window.addEventListener('keydown', this.smashKeyboard)
    window.addEventListener('keyup', this.releaseKeyboard)

    for (const img of imagesClicker) {
      this.$store.dispatch("PRELOAD_PICTURE", img);
    }

    this.tiemout = setTimeout(() => {
      this.opening = true;
      this.fakeSmash();
    },100)

    this.runtimeInterval = setInterval(this.clearAnimation, 50)
  },
  unmounted() {
    window.removeEventListener('keydown', this.smashKeyboard)
    window.removeEventListener('keyup', this.releaseKeyboard)
    clearTimeout(this.timer);
    clearTimeout(this.intervalFakeSmash);
    clearTimeout(this.tiemout);
  }
}
</script>
