<template>
  <Diapo id="opening-chest" :class="{closing}"
      :skip="false"
      :steps="stepImage"
      :prev="true"
      @skipped="close"
  />
</template>

<script>
import Diapo from "@/components/Diapo";
export default {
  name: "Movie",
  components: {Diapo},
  data:() => ({
    closing:false
  }),
  computed:{
    stepImage(){
      return [
        {image:'step/step_00.jpg', title:'The grand opening!', text:'The chest finally opens up after a long and exhausting battle! <br />' +
              'They were expecting Gold, diamonds, sodium.. <br />With great anticipation, the pickles could hardly hold back their glee ' +
              'and impatience.', },
        {image:'step/step_10.jpg', title:'THE MYSTERY BOX', text:'Behold, the chest\'s door opens to treasures that the pickles could hardly fathom... \n' },
        {image:'step/step_20.jpg', title:'Pickle fest', text:'Gazing down, clenched in their hands, were tickets to the one and only Pickle Fest: but not just any Pickle Fest, THE SIX DAY PICKLE FEST!', },
        {image:'step/step_45.jpg', title:'The gathering', text:'Finally, the moment that they had all been waiting for... their excitement and anticipation\n' +
              'had finally come at bay. One by one, the pickles stood in line\n' +
              'presenting their tickets for the best festival that had yet to come.', },
        {image:'step/step_30.jpg', title:'Dj Gold Pickle', text:' The Gold Pickle arrived with class to welcome all the pickles to the fucking party.<br/>' +
              'A moment later the Dj started to warm up the crowd!', },
        {image:'step/step_40.jpg', title:'Saturday night fever', text:'The night started hard, DJ Fucking Gold Pickle rocked the chest with his mighty fucking bass!', },
        {image:'step/step_50.jpg', title:'Fire on the DANCEFLOOR', text:'As the night went on, they partied like fucking MAD pickles', },
        {image:'step/step_60.jpg', title:'All night long', text:'These were no longer the mad pickles, but the highly intoxicated, sodium induced pickles\n' +
              'that raged on! Until...', },
        {image:'step/step_70.jpg', title:'Busted!', text:'The one they call “human”, reaches for the light switch after being awoken \n' +
              'by an odd sound coming from the kitchen.\n', },
        {image:'step/step_80.jpg', title:'Party’s over boys', text:'It was chaos, the Pickles were panicking, screaming and feared for what was coming for them.', },
        {image:'step/step_90.jpg', title:'Everyone for themselves', text:'The sodium induced pickles trampled over each other as they persued to run\n' +
              ' for their fucking lives.\n' +
              ' ', },
        {image:'step/step_100.jpg', title:'The trials begin', text:'The pickles were now facing death head on, one wrong move and it could all be over.\n' +
              ' Choose your path wisely, your life depends on it.', },
      ]
    }
  },
  methods:{
    async close(){
      this.$root.showMovie = false;
      this.closing = true;
      await this.$tools.sleep(3000)

      this.$root.openingChest = false;
    }
  }
}
</script>
