<template>
  <div>
    <router-view v-slot="{Component}">
        <!-- <transition :name="route.name === 'Home' ? '' : 'route'" mode="out-in"> -->
            <component :is="Component"></component>
        <!-- </transition> -->
    </router-view>
    <Movie v-if="$root.showMovie"/>
    <Notif/>
    <div class='overlay' v-if="showOverlay"></div>
  </div>
</template>

<script>
import sounds from "./class/sounds";
import contract from "@/class/contract";
import Notif from "@/components/Notif";
import Movie from "@/pages/Movie";

export default {
  name: 'App',
  components: {Movie, Notif},
  data:() => ({
    showDetailsDayId:-1,
    showDailyOpen:false,
    showDiapo:false,
    showOverlay:false,
    showPopup:null,
    showMovie:false,
    finalRecap:-1,

    chestEnd:true,
    clickerEnd:true,
    openingChest:false,
    soundFiles: [
      "click.mp3", "less.mp3", "click-nice.mp3", "jar.mp3", "hit-1.mp3", "hit-2.mp3", "hit-3.mp3", "hit-4.mp3", "hit-5.mp3", "hit-6.mp3",
      "hit-7.mp3", "hit-8.mp3", "hit-9.mp3",
      "sword-1.mp3", "sword-2.mp3", "sword-3.mp3", "win-1.mp3",
      "key-unlock.mp3","chest-open.mp3"
    ]
  }),
  async created() {
    sounds.init(this.soundFiles);
  },
  async mounted() {

    document.body.classList.add('full-loaded')

    await contract.init(this.contractsInit)

    this.emitter.on("web3modal:connect", this.web3modal)
    this.emitter.on("web3modal:connected", this.connected)

    if(contract.checkConnectModal()){
      await this.web3modal(false)
    }
  },
  unmounted() {
    this.emitter.off("web3modal:connect", this.web3modal)
    this.emitter.off("web3modal:connected", this.connected)
  },
  methods: {
    async contractsInit(_contract) {

      await _contract.addContract("PickleEscape");

      this.$store.commit('CONTRACT_LOADED')

    },
    async web3modal(notif = true) {

      if (this.$store.getters.isConnected) return;

      this.emitter.emit("web3modal:connect:processing")

      return new Promise((resolve, reject) => {
        return contract.connectModal(async (_contract, account) => {

              this.$store.commit('SET_USER_WALLET', account)

              await this.contractsInit(_contract);

              if(notif){
                this.emitter.emit("alert:success", "global_connected");
              }

              this.emitter.emit("web3modal:connect:success")

              resolve()
            },
            (state) => {
              console.log(state)
              switch (state) {
                case "bad-network" :
                  this.emitter.emit("alert:error", "global_bad_network");
                  break;
                case "no provider ethereum" :
                  this.emitter.emit("alert:error", "global_no_metamask");
              }
              reject(state)
            });
      })
    },

    async connected() {
      this.emitter.emit("web3modal:connect:success")
    }
  }
}
</script>

