<template>

  <div class='wrapper-gradient'>
    <div class='gradient'></div>
  </div>
  <section id='hero'>
    <header>
      <div class='container row'>
        <div class='logo-box'>
          <div class='logo'></div>
        </div>
        <div class='burger' @click.prevent="menuBurger = !menuBurger"><img src='/images/burger.svg' alt='burger'></div>
        <div class='nav row' :class="{hidden:!menuBurger}">

          <a href='#game' class='before' v-if="season.getUserHasJar() && season.getUserPickles() > 0 && !season.isStateStarted() && season.isLoaded() && season.isClaimStarted()">Play</a>
          <a href='#' @click.prevent.stop="scrollToMint" class='before' v-else>Play</a>

          <a :href='$config.rulesURL' target="_blank" class='before'>Rules</a>
          <a href='https://myfuckingpickle.io/' class='before'>My Fucking Pickle</a>
          <div class='social-wrapper row'>
            <a href='https://twitter.com/MyFuckingPickle' class='social' target='_blank'>
              <svg class='ico'>
                <use xlink:href='#icon-twitter'></use>
              </svg>
            </a>
            <a href='https://discord.com/invite/5r5Kt6s5v3' class='social' target='_blank'>
              <svg class='ico'>
                <use xlink:href='#icon-discord'></use>
              </svg>
            </a>
          </div>
          <a href='#' class='highlighted' v-if="$store.getters.isConnected" @mouseleave="connectText = false" @mouseenter="connectText = true" @click.prevent.stop="disconnect">
            {{ connectText ? 'Disconnect' :  $filters.addressSub($store.getters.wallet)}}
          </a>
          <a href='#' class='highlighted' v-else @click="connect">
            Connect your wallet
          </a>
        </div>
      </div>
    </header>
    <div class='wrapper-hero'>
      <div class='container'>
        <img src='/images/logo-game.svg' alt='logo' class='logo-game'>
        <div class='actions row'>
          <div class='cta' @click.prevent.stop="connect" v-if="!$store.getters.isConnected">Connect your wallet</div>
          <a class='cta' href="#" @click.prevent.stop="scrollToMint" v-else>Enter the game</a>
          <div class='cta' @click.prevent.stop="showMovie"><img src='/images/triangle.svg' alt='play'></div>
        </div>
      </div>
    </div>
  </section>

  <section id='presentation' class='container row'>
    <div class='left'>
      <div class='img-wrapper'></div>
    </div>
    <div class='right'>
      <h2>What the fuck is<br/> <span class='green'>Pickle Escape?</span></h2>
      <p>
        Pickle Escape is a blockchain game in which every day at dawn, you will have to choose a path to leave the golden pickle party without humans seeing you... Beware, the paths are dangerous and sometimes not very reliable! Most travelled paths will be slashed except the one that has the least amount of pickles.
        Every player can influence the outcome by their choices!
        Choose wisely & don’t trust anyone!
      </p>
      <a :href='$config.rulesURL' target="_blank" class='cta'>Read the fucking rules</a>
    </div>
  </section>

<!--  <section id='mint' class='container' v-if="season.isBeforeClaimStarted() && season.isLoaded()">-->
<!--    <div class='wanna-wrapper line-height'>-->
<!--      <div class='wanna'>-->
<!--        {{ state['claimStarted'] ? 'OPENING' : 'Season start in' }}-->
<!--        <countdown-->
<!--            :end="season.getStartSeasonTime()"-->
<!--            :days="true"-->
<!--            :after="{days:'d ',hours:':', minutes:':'}"-->
<!--            @end="state['claimStarted'] = true"-->
<!--        />-->
<!--      </div>-->

<!--    </div>-->
<!--  </section>-->

  <section id='mint' class='container' ref="mint"
           v-if="!season.getUserHasJar() && !season.isStateStarted() && season.isLoaded()">
    <div class='wanna-wrapper'>
      <div class='wanna'>Wanna play?</div>
      <div class='baseline'>To play, you only need a jar.<br/><span class='green'>It’s free </span>&<span class='green'> 100% gasless!</span>
      </div>
    </div>
    <div class='center row'>
      <div class='left'>
        <div class='left-wrapper column'>
          <p class='mb30'>The jar gives you permanent access to the game Pickle Escape as long as you hold one. Everyone can claim one for free until there is no supply left.<br/>
            You will have no fees, only a signature with your wallet.
          </p>
          <div class='data row'>
            <div class='overview currentcashprize column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>Players</div>
                  <div class='border-top'></div>
                </div>
                <div class='content row'>
                  <div class='bignumber'>{{ $filters.number(season.getTotalWallets()) }} <span
                      class='small'>players</span></div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
            <div class='overview start column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>Jar</div>
                  <div class='border-top'></div>
                </div>
                <div class='content row'>
                  <div class='bignumber'>{{ $filters.number(season.getSupplyLeft()) }}<span
                      class='small'> jars left</span></div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
          </div>

          <div class='overview column'>
            <div class='overview-left'></div>
            <div class='wrapper-border column'>
              <div class='top row'>
                <div class='overview-title'>What about my fucking pickles</div>
                <div class='border-top'></div>
              </div>
              <div class='content row'>
                <img src='/images/pickle-og.png' alt='OG Pickle' class='og'>
                <p>
                  At the begining of every seasons, <span class='bold'>My Fucking Pickle</span> holders can fill their jars with as many pickles as MFP NFTs they own.
                </p>
              </div>
            </div>
            <div class='overview-right'></div>
          </div>

          <div class='data row'>
            <div class='overview currentcashprize column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>*Cashprize</div>
                  <div class='border-top'></div>
                </div>
                <div class='content row'>
                  <div class='bignumber'>{{ $filters.fromWei(season.getCashPrice()) }} <span class='small'>ETH</span>
                  </div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
            <div class='overview start column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>Game Start</div>
                  <div class='border-top'></div>
                </div>
                <div class='content row'>
                  <div class='bignumber'>
                    <span class='small' v-if="!state['endClaim']">in </span>
                    <loader text="Beginning" class='small' v-else/>
                    <countdown
                        style="font-size:2.2rem"
                        :end="season.getEndClaimTime()"
                        surcharge-class="countdown-small"
                        :days="true"
                        :after="{ days:'d ',hours:':',minutes:':'}"
                        @end="state['endClaim'] = true"
                    />
                  </div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
            <div class='overview duration column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>Duration</div>
                  <div class='border-top'></div>
                </div>
                <div class='content row'>
                  <div class='bignumber'>6 <span class='small'>days</span></div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
          </div>
          <p class='cashprice-details'>* Cashprize can be increased during the game</p>
        </div>
      </div>
      <div class='right colmun'>
        <img src='/images/jar/jar_0.jpg' class='img-jar'>
        <div class='wrapper-link'>

          <div class='link column link-loading' v-if="state['endClaim']">
            <div>claim closed</div>
          </div>

          <div class='link column link-loading' v-if="!season.isClaimStarted()">
            <div>Free claim open in </div>
            <countdown
                :end="season.getStartSeasonTime()"
                surcharge-class="countdown-small"
                :after="{ hours:':',minutes:':'}"
            />
          </div>

          <div class='link column' @click.prevent.stop="claimJar" v-else-if="!season.getUserHasJarPending()">
            <div>claim a jar for free</div>
            <div class='gasless'>Totally gasless</div>
          </div>

          <div class='link column link-loading' v-else>
            <div>
              <div>Delivery of your jar</div>
              <div class='gasless'>
                <loader text="Minting"/>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section id='mint' class='container' ref="mint"
           v-if="season.getUserHasJar() && season.getUserPickles() === 0 && !season.isStateStarted() && season.isLoaded() && season.isClaimStarted()">
    <div class='wanna-wrapper'>
      <div class='wanna'>Wanna play?</div>
      <div class='baseline'>Fill your jar with pickles</div>
    </div>
    <div class='center row'>
      <div class='left'>
        <div class='left-wrapper column'>
          <p class='mb30'>
            Before playing, you must fill your jar with your pickles. Once in game you will have to choose a path for all of your pickles.<br/>
            Remember, the most traveled paths are those that will have the most losses!<br/>
            While waiting for the game to start, we advise you to <a :href='$config.rulesURL' target="_blank">read the fucking rules</a>.
          </p>

          <div class="data row col50">
            <div class='overview start mb60 column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>Next season</div>
                  <div class='border-top'></div>
                </div>
                <div class='content row'>
                  <div class='bignumber'>
                    Season {{ season.getSeasonId() }}
                  </div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
            <div class='overview start mb60 column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>Game Start</div>
                  <div class='border-top'></div>
                </div>
                <div class='content row'>
                  <div class='bignumber'>
                    <span class='small' v-if="!state['endClaim']">in </span>
                    <loader v-else class="small" text="Beginning"/>
                    <countdown
                        :end="season.getEndClaimTime()"
                        surcharge-class="countdown-small"
                        :after="{ hours:':',minutes:':'}"
                        @end="state['endClaim'] = true"
                    />
                  </div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
          </div>
          <p>Every My Fucking Pickle holders receive <span class='bold'>4 pickles + 1 pickle per MFP</span> they hold.</p>
          <p style="padding:10px 0;">
            The holders snapshot has already been taken, so if you buy a MFP now, you will receive extra pickle(s) from the next season.
          </p>

          <div class='data row col50'>
            <div class='overview currentcashprize column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>You own</div>
                  <div class='border-top'></div>
                </div>
                <div class='content row'>
                  <div class='bignumber'>{{ $filters.number(season.getUserBalanceMFP()) }} <span
                      class='small'>MFP</span></div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
            <div class='overview start column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>You get</div>
                  <div class='border-top'></div>
                </div>
                <div class='content row'>
                  <div class='bignumber'><span class='small'>up to </span>{{ maxPickle }}<span
                      class='small'> pickles</span></div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
          </div>
        </div>
      </div>
      <div class='column'>
        <div class='right colmun'>
          <img :src='jarImage' class='img-jar'>
          <div class='quantity row' v-if="season.getUserPicklePending() === 0 && !state['endClaim']">
            <div
                class='btn row'
                :class="{'lock':totalCount <= minPickle}"
                @mousedown="lessIntStart"
                @mouseup="lessIntStop"
                @mouseleave="lessIntStop"
            >
              -
            </div>
            <div class='number'>
              {{ totalCount }}
            </div>
            <div
                class='btn row'
                :class="{'lock':totalCount >= maxPickle}"
                @mousedown="moreIntStart"
                @mouseup="moreIntStop"
                @mouseleave="moreIntStop"
            >
              +
            </div>
          </div>
          <div class='wrapper-link ' v-if="state['endClaim']">
            <div class='link link-loading'>Claim close</div>
          </div>
          <div class='wrapper-link' @click.prevent.stop="setPickles" v-else-if="season.getUserPicklePending() === 0">
            <div class='link'>I’m ready to play!</div>
          </div>
          <div class='wrapper-link' v-else>
            <div class='link link-loading'>
              <div>
                <div>Filling the jar</div>
                <div class="gasless">
                  <loader text="Processing"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href='#' class='another-jar' v-if="1==2">Select another jar</a>
      </div>
    </div>
  </section>

  <section id='mint' class='container' ref="mint"
           v-if="season.getUserHasJar() && season.getUserPickles() > 0 && !season.isStateStarted() && season.isLoaded() && season.isClaimStarted()">
    <div class='wanna-wrapper line-height'>
      <div class='wanna' >
        {{ !state['endClaim'] ? 'You are ready!' :'Starting game' }}
      </div>
      <div class='countdown'>
        <div v-if="!state['endClaim']" class="baseline ml-0">
          THE <span class="green">GAME</span> BEGINS IN
          <span class='green'>
            <countdown
                surcharge-class="countdown-small"
                :end="season.getEndClaimTime()"
                :after="{hours:':',minutes:':'}"
                @end="state['endClaim'] = true"
            />
          </span>
        </div>
        <div class="baseline ml-0" v-else>
          <loader text="Please wait"/>
        </div>
      </div>
    </div>
  </section>

  <section id='mint' class='container' ref="mint" v-else-if="season.isStateStarted() && season.isLoaded()">
    <div class='wanna-wrapper line-height'>
      <div class='wanna' v-if="!state['endSeason']">
        Season {{ season.getSeasonId() }}<br/>
        Is Running
      </div>
      <div class='wanna' v-else>
        Season {{ season.getSeasonId() + 1 }} finished
      </div>

      <div class="baseline ml-0" v-if="!state['endSeason']">
        Season <span class='green'>ending</span> in
        <span class='green'>
            <countdown
                surcharge-class="countdown-small"
                :end="season.getNextSeasonTime()"
                :days="true"
                :after="{days:'d ',hours:':',minutes:':'}"
                @end="state['endSeason'] = true"
            />
          </span>
      </div>
      <div class="bottom" v-else>
        <div class="cta" @click.prevent.stop="$router.push('game')">
          Recap season {{ season.getSeasonId() + 1 }}
        </div>
      </div>
      <div class="bottom" v-if="!state['endSeason']">
        <div class="cta" @click.prevent.stop="$router.push('game')">
          Enter the game
        </div>
      </div>
    </div>
  </section>

  <section id='mint' class='container' ref="mint" v-else-if="!$store.getters.isConnected">
    <div class='wanna-wrapper line-height'>
      <div class='wanna'>
        Connect your wallet
      </div>
      <div class="bottom">
        <div class="cta" @click.prevent.stop="connect">
         Connect your wallet
        </div>
      </div>
    </div>
  </section>

  <section id='mint' class='container' ref="mint" v-else-if="!season.isLoaded()">
    <div class='wanna-wrapper line-height'>
      <div class='wanna'>
        <loader/>
      </div>
    </div>
  </section>

  <footer class="w-discord">
    <a href='https://discord.com/invite/5r5Kt6s5v3' id='discord' target="_blank">
      <div class='wrapper-discord'>
        <div class='container'>
          <img src='/images/community.svg' class='community'>
          <div class='shape'></div>
        </div>
      </div>
    </a>
    <div class='container'>
      <div class='spacer'></div>
      <div class='bottom row'>
        <div class='nav row'>
          <a href='#terms-of-use' class='before'>Terms of use</a>
          <a href='#privacy-policy' class='before'>Privacy Policy</a>
        </div>
        <div class='copyright row'>
          <div class='powered'>Powered by</div>
          <a href='https://www.miinded.com/' target="_blank" class='miinded'></a>
        </div>
      </div>
    </div>
  </footer>

  <Popup v-if="popupClaim" :content="popupClaim" @close="popupClaim = null">
    <PopupMint
        @valid="(valid) => popupClaimValid = valid"
    />
  </Popup>
  <Popup v-if="popupPickle" :content="popupPickle" @close="popupPickle = null">
    <PopupSetPickle
        @valid="(valid) => popupPickleValid = valid"
    />
  </Popup>
</template>

<script>
import Countdown from "@/components/Countdown";
import contract from "@/class/contract";
import Loader from "@/components/Loader";
import Popup from "@/components/Popup";
import PopupMint from "@/components/_popup/popup-mint";
import api from "@/class/api";
import PopupSetPickle from "@/components/_popup/popup-set-pikle";

export default {
  name: "Public",
  components: {PopupSetPickle, PopupMint, Loader, Countdown, Popup},
  data: () => ({
    totalCount: 1,

    delayCount: 100,
    mintClose: false,

    popupClaim: null,
    popupClaimValid: false,

    popupPickle: null,
    popupPickleValid: false,

    gameStarted: false,

    connectText: "",
    menuBurger: false,

    state: {}
  }),
  methods: {
    scrollToMint(){
      this.$smoothScroll({
        scrollTo: this.$refs.mint, // scrollTo is also allowed to be number
        updateHistory:false,
        duration:0
      })
    },
    async more() {
      this.totalCount++;
      if (this.totalCount >= this.maxPickle) {
        this.totalCount = this.maxPickle
      }

    },
    moreIntStart() {

      if(this.totalCount === this.maxPickle){
        return this.emitter.emit('alert:info','global_need_more_mfp')
      }

      this.more()

      this.moreInt = setTimeout(() => {
        this.moreIntStart()

        this.delayCount -= 2;
        if (this.delayCount <= 1) {
          this.delayCount = 1
        }

      }, this.delayCount)
    },
    moreIntStop() {
      clearInterval(this.moreInt)
      this.delayCount = 100
    },
    async less() {

      this.totalCount--;
      if (this.totalCount < this.minPickle) {
        this.totalCount = this.minPickle
      }
    },
    lessIntStart() {
      this.less()

      this.moreInt = setTimeout(() => {
        this.lessIntStart()

        this.delayCount -= 2;
        if (this.delayCount <= 1) {
          this.delayCount = 1
        }

      }, this.delayCount)
    },
    lessIntStop() {
      clearInterval(this.moreInt)
      this.delayCount = 100
    },
    showMovie(){
      this.$root.showMovie = true;
      console.log(this.$root.showMovie)
    },
    connect(){
      this.emitter.emit("web3modal:connect")
    },
    disconnect(){
      if (window.web3Modal) {
        window.web3Modal.clearCachedProvider()
      }
      document.location.reload()
    },

    async claimJar() {

      this.popupClaimValid = false;

      await new Promise((resolve, reject) => {

        this.popupClaim = {
          title: "Claim your Jar",
          cancel: "Cancel",
          valid: "Yes, Got it",
          resolve: () => {
            if (!this.popupClaimValid) {
              return this.emitter.emit("alert:error", "global_mint_rules_not_ok")
            }
            this.popupClaim = null;
            resolve();
          },
          reject: reject,
        }

      })

      this.emitter.emit("alert:loader", "global_claim_signing")

      const message = {owner: this.$store.getters.wallet}
      let signature;
      try {
        signature = await contract.createSignature(message, this.$store.getters.wallet)
      } catch (e) {
        this.emitter.emit("alert:loader:hide")
        return this.emitter.emit("alert:error", "global_claim_signing_error")
      }

      this.emitter.emit("alert:loader:hide")
      this.emitter.emit("alert:loader", "global_claim_loading")

      const result = await api.post("game/claim", {
        message, signature
      })

      if (result.error()) {
        this.emitter.emit("alert:loader:hide")
        return this.emitter.emit("alert:error", ["global_claim_error", {message: result.errorMsg()}])
      }

      await this.$store.dispatch("UPDATE_SEASON")

      this.emitter.emit("alert:loader:hide")
      this.emitter.emit("alert:success", "global_claim_success")
      console.log(result.payload())
    },
    async setPickles() {

      this.popupPickleValid = false;

      await new Promise((resolve, reject) => {

        this.popupPickle = {
          title: "Fill your jar",
          cancel: "Cancel",
          valid: "Yes, Got it",
          resolve: () => {
            if (!this.popupPickleValid) {
              return this.emitter.emit("alert:error", "global_set_pickle_not_ok")
            }
            this.popupPickle = null;
            resolve();
          },
          reject: reject,
        }

      })

      this.emitter.emit("alert:loader", "global_set_pickle_signing")

      const message = {
        owner: this.$store.getters.wallet,
        jarId: this.season.getUserJarId(),
        count: this.totalCount
      }
      let signature;
      try {
        signature = await contract.createSignature(message, this.$store.getters.wallet)
      } catch (e) {
        this.emitter.emit("alert:loader:hide")
        return this.emitter.emit("alert:error", "global_claim_signing_error")
      }

      this.emitter.emit("alert:loader:hide")
      this.emitter.emit("alert:loader", "global_claim_loading")

      const result = await api.post("game/set/pickles", {
        message, signature
      })

      if (result.error()) {
        this.emitter.emit("alert:loader:hide")
        return this.emitter.emit("alert:error", ["global_claim_error", {message: result.errorMsg()}])
      }

      await this.$store.dispatch("UPDATE_SEASON")

      this.emitter.emit("alert:loader:hide")
      this.emitter.emit("alert:success", "global_claim_success")
      console.log(result.payload())
    }
  },
  computed: {
    season() {
      if(this.$store.getters.season.getNextSeason()){
        return this.$store.getters.season.getNextSeason();
      }
      return this.$store.getters.season;
    },
    countPayable() {
      let total = this.totalCount - this.countFree
      if (total <= 0) {
        total = 1;
      }
      if (total < this.countMaxFree && this.isFreeOnly) {
        return 0
      }
      return total
    },
    countFree() {
      if (this.countMaxFree > this.totalCount) {
        if (this.isFreeOnly) {
          return this.totalCount;
        }
        return this.totalCount - 1;
      } else {
        return this.countMaxFree
      }
    },
    jarImage(){
      let count = this.totalCount > 20 ? 20 : this.totalCount;
      return '/images/jar/jar_'+count+".jpg"
    },
    countMaxFree() {
      return this.season.getUserMaxFree() - this.season.getUserBalanceFreeMint()
    },
    isFreeOnly() {
      return this.season.getUserBalanceFreeMint() > 0 && this.countMaxFree > 0 && this.totalCount <= this.countMaxFree
    },
    price() {
      if (this.isFreeOnly) {
        return 0
      }
      const p = this.season.getPrice().toString();
      const price = new contract.web3.utils.BN(p);

      return price.mul(new contract.web3.utils.BN(this.countPayable))

    },
    mintCloseOrSoldOut() {
      return this.mintClose || this.season.getTotalSupplyMint() >= 30000
    },
    maxPickle() {
      const MFP = this.season.getUserBalanceMFP();
      if (MFP > 0) {
        return this.season.getUserBalanceMFP() + 4
      }
      if(this.season.getUserJarId() <= 5000){
        return 2
      }
      return 1
    },
    minPickle() {
      const MFP = this.season.getUserPickles() + this.season.getUserPicklePending();
      if (!MFP) {
        return 1
      }
      return MFP
    },
    getUserHasJarPending(){
      return this.season.getUserHasJarPending()
    },
    getUserPicklePending(){
      return this.season.getUserPicklePending()
    }
  },
  watch: {
    getUserHasJarPending(value, previous){
      if(value === 0 && previous > 0){
        this.emitter.emit('alert:success', 'global_jar_minted_success')
      }
    },
    getUserPicklePending(value, previous){
      if(value === 0 && previous > 0){
        this.emitter.emit('alert:success', 'global_pickle_minted_success')
      }
    },
    "countMaxFree"() {
      this.totalCount = this.countMaxFree;
      if (this.totalCount <= 0) {
        this.totalCount = 1
      }
    },
    minPickle() {
      if (this.totalCount === 1) {
        this.totalCount = this.minPickle
      }
    }
  },
}
</script>
