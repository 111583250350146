<template>
  <div class='overlay row'>
    <div class='container modal column'>
      <div class='top'>
        <h5>{{ content.title }}</h5>
        <slot/>
        <div class='pushs row'>
          <div class='cta tertiary' v-if="content.cancel" @click.stop.prevent="cancel()">{{ content.cancel }}</div>
          <div class='cta' v-if="content.valid"  @click.stop.prevent="valid()">{{ content.valid }}</div>
        </div>
      </div>
      <div class='bot' v-if="content.subtext">
        <div class='info row'>
          <div class='picto'></div>
          <div class='txt'>{{ content.subtext }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  components:{},
  props:{
    content:Object,
  },
  data:() => ({
  }),

  methods:{
    valid(){
      this.content.resolve();
    },
    cancel(){
      this.$emit('close');
      this.content.reject();
    }
  },
}
</script>
