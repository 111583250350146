import {Way} from "@/class/way";
import global from "../store/global";

export class Day {
    constructor(data) {
        if (!data) return this;

        for(const key in data){
            if(key === "ended" && data["ended"] != null){
                this.ended = data["ended"]
                const ways = []
                for(const way of data["ended"]["ways"]){
                    ways.push(new Way(way))
                }
                this.ended.ways = ways;
            }else {
                this[key] = data[key];
            }
        }
    }
    isDone(){
        return this.state === 10
    }
    isProcessing(){
        return this.state >= 3
    }
    isFinal(){
        return this.getDayId() >= 5
    }
    getDayId(){
        return this.dayId;
    }
    getState(){
        return this.state
    }
    getAlive(){
        if(!this.alive){
            return 0;
        }
        return this.alive;
    }
    getSelect(){
        return this.select;
    }
    getSelected(){
        if(!this.select){
            return 0
        }
        return this.select.selected
    }
    getTime(){
        return this.select.time;
    }
    getTimeStart(){
        return this.select.time.start;
    }
    getUserSelectWayId(){
        return this.select.userWayId;
    }
    getTimeEnd(){
        if(!this.getSelect()){
            return ''
        }
        return new Date(this.select.time.end * 1000).toISOString();
    }
    getEnded(){
        return this.ended
    }
    getKilled(){
        if(!this.ended){
            return 0
        }
        return this.ended.killed
    }
    getSelectBadWay(){
        if(!this.ended){
            return 0
        }
        return this.ended.selectBadWay
    }
    getWays(){
        return this.ended.ways
    }
    getWaysAlive(){
        const paths = this.ended.ways.slice(0, this.ended.ways.length - 1)

        paths.sort((p1, p2) => {
            return p1.getKilled() < p2.getKilled() ? -1 : 1
        })

        return paths
    }
    getWayDead(){
        console.log(this.ended.ways[ this.ended.ways.length - 1])
        return this.ended.ways[ this.ended.ways.length - 1]
    }
    getWay(wayId){
        return this.ended.ways.find(w => w.wayId === wayId)
    }
    getWinnerWayId(){
        if(!this.ended){
            return -1
        }
        return this.ended.winnerWayId;
    }
    getPictures(){
        return this.pictures
    }
    getPictureURL(pictureId){
        let path = "path/path";
        let ext = "jpg";
        if(this.isFinal()){
            path = "plane_pickle/plane"
            ext = "png"
        }
        // @TODO changer ca
        // return envs.BACKEND_URI+"pictures/"+this.pictures[pictureId]
        let url = "/images/"+path+"_"+pictureId+"."+ext
        global.dispatch('PRELOAD_PICTURE', url)
        return url
    }
    getWayPictureURL(wayId, dead){
        // @TODO changer ca
        // return envs.BACKEND_URI+"pictures/"+this.pictures[pictureId]
        console.log(dead)
        let picture = !dead ? "path/path_" : "dead/dead_";

        let url = "/images/"+picture+this.getPictures()[wayId]+".jpg"
        global.dispatch('PRELOAD_PICTURE', url)
        return url
    }
    getUserPickleAlive(){
        return this.userPickleAlive;
    }
    getUserPickleDead(){
        return this.userPickleDead;
    }
    getUserValidDeathScreen(){
        return this.ended && this.ended.userValidDeathScreen;
    }
    getBonus(bonusId){
        if(!this.bonus){
            return false
        }
        return this.bonus.find((b) => b.bonusId === bonusId) !== undefined
    }
    getJarIds(){
        if(!this.jarIds) return []
        return this.jarIds;
    }
    getSpyResult(){
        if(!this.spyResult) return null
        return this.spyResult
    }
}
