<template>
  <div class='notif container' :class="{'disable': alerts.length === 0}">
    <div
        class='alert'
        v-for="alert in alerts"
        :key="alert.id"
        :class="alert.type"
    >
      <img :src="'/images/notif_'+alert.type+'.jpg'" alt="" class="icon">
      <div class='icon'></div>
      <div class='information'>
        <div class='title' v-if="alert.type !== 'loader'">
          {{ alert.title }}
        </div>
        <div class='title' v-else>
          <loader :text="alert.title" />
        </div>
        <div class='text'>{{ alert.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
export default {
  name: "Notif",
  components: {Loader},
  props: {
    type: String,
    message: String,
  },
  data() {
    return {
      alerts: [],
      loaderAlert:null
    }
  },
  methods: {
    show(delay = 5000) {
      console.log(this.alerts)
      if(delay > 0) {
        setTimeout(() => {
          this.alerts = this.alerts.slice(1, this.alerts.length)
        }, delay)
      }
    },
    success(data) {
      this.alerts.push({
        type: "success",
        title: "Success",
        message: this.$t(data),
        id: Date.now()
      })
      this.show()
    },
    error(data) {
      this.alerts.push({
        type: "error",
        title: "Error",
        message: this.$t(data),
        id: Date.now()
      })
      this.show()
    },
    info(data) {
      this.alerts.push({
        type: "info",
        title: "Info",
        message: this.$t(data),
        id: Date.now()
      })
      this.show()
    },
    close(alert){
      this.alerts = this.alerts.filter(a => a !== alert)
    },
    loader(data) {
      this.loaderHide();
      this.alerts.push({
        type: "loader",
        title: "Waiting",
        message: this.$t(data),
        id: Date.now()
      })
      this.loaderAlert = this.alerts[this.alerts.length - 1]
      this.show(0)
      this.$root.showOverlay = true;
    },
    loaderHide(){
      if(!this.loaderAlert){
        return;
      }
      this.close(this.loaderAlert)
      this.$root.showOverlay = false;
      this.loaderAlert = null
    }
  },
  mounted() {
    this.emitter.on("alert:success", this.success)
    this.emitter.on("alert:error", this.error)
    this.emitter.on("alert:info", this.info)
    this.emitter.on("alert:loader", this.loader)
    this.emitter.on("alert:loader:hide", this.loaderHide)
  },
  unmounted() {
    this.emitter.off("alert:success", this.success)
    this.emitter.off("alert:error", this.error)
    this.emitter.off("alert:info", this.info)
    this.emitter.off("alert:loader", this.loader)
    this.emitter.off("alert:loader:hide", this.loaderHide)
  }
}
</script>
