<template>

  <div class="diapos">
    <div class="diapo">

      <div class="diapo-image">

        <img :src="'/images/'+current.image" alt="">
      </div>

      <div class="diapo-bottom">
        <div class="row">
          <div class="logo"></div>
          <div class="diapo-text">
            <div class="diapo-title" v-if="current.title">
              {{ current.title }}
            </div>
            <div v-html="current.text"></div>
          </div>
          <div class="buttons row">
            <div class="cta" @click.prevent.stop="preved" v-if="prev && key > 0">
              Previous
            </div>
            <div class="cta" @click.prevent.stop="nexted" v-if="!closing">
              {{ key === steps.length - 1 ? "Close" : "Next"}}
            </div>
            <div class="cta secondary" v-if="skip && key < steps.length - 1 && !closing"  @click.prevent.stop="skipped">
              Skip
            </div>
            <div class="cta" v-if="closing">
              <loader />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import Loader from "@/components/Loader";
export default {
  name: "Diapo",
  components: {Loader},
  props: {
    skip: {type: Boolean, default: false},
    steps: {type: Array, default: () => []},
    prev:{type:Boolean, default:false},
  },
  data: () => ({
    key: 0,
    closing:false
  }),
  methods:{
    nexted(){
      if(this.key === this.steps.length - 1){
        return this.skipped()
      }

      this.key ++;
    },
    preved(){
      if(this.key === 0){
        return
      }

      this.key --;
    },
    skipped(){
      this.closing = true;
      this.$emit('skipped')
    }
  },
  computed: {
    current() {
      return this.steps[this.key]
    }
  },
  mounted(){
    const imagesDiapo = [
      "/images/step/step_5.jpg",
      "/images/step/step_10.jpg",
      "/images/step/step_20.jpg",
      "/images/step/step_30.jpg",
      "/images/step/step_40.jpg",
      "/images/step/step_50.jpg",
      "/images/step/step_60.jpg",
      "/images/step/step_70.jpg",
      "/images/step/step_80.jpg",
      "/images/step/step_90.jpg",
      "/images/step/step_100.jpg",
    ]
    for(const img of imagesDiapo) {
      this.$store.dispatch("PRELOAD_PICTURE", img)
    }
  }
}
</script>
