import { createApp } from 'vue'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import VueNextSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'

// import des routes
import { createRouter, createWebHashHistory} from "vue-router"
import routes from "./routes"

import "@/assets/scss/app.scss";

// import ENV

// import mitt
import mitt from 'mitt';
const emitter = mitt();
import config, { envs } from './config';


// gestion de devtool
const devtools = {
	install(app) {
		// @ts-ignore
		if (envs.NODE_ENV === 'development' && window.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
			// @ts-ignore
			window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
		}
	},
};

// gestion des routes
const router = createRouter({
	history: createWebHashHistory(),
	base: "",
	fallback: true,
	routes,
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
});

// création de l'app
const app = createApp(App);
app.use(devtools);
app.use(router);

app.config.globalProperties.emitter = emitter;
app.component('vue-select', VueNextSelect)
app.use(VueSmoothScroll, {
	duration: 500
})
// ajout des fn tools

app.config.globalProperties.$config = config

// ajout des filters
import filters from "./filters";
app.config.globalProperties.$filters = filters

// ajout des fn tools
import { tools } from "./utils";
app.config.globalProperties.$tools = tools

app.config.globalProperties.$t = tools.textsFormat
app.config.globalProperties.$app = app

// import du store global
import globalStore from "./store/global"
app.use(globalStore);


app.mount('#app')

