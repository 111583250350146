<template>
  <section id='mint' class='container processing-bg'>
    <div class="big-img" @click.prevent.stop="showDance += 1" v-if="showDance < 10">
      <img src="/images/step/step_80.jpg" alt="">
    </div>
    <div  v-else>
      <div class="subtitle-fil" v-if="totalClick > 0">
        {{totalClick}} click{{totalClick > 1 ? 's':''}}
        <div>
          {{
            [
              'All this is useless...',
              'I don\'t know if you noticed but when you click, the progress bar increases!',
              'Do you really think clicking will speed up the blockchain?',
              'You would like to be the last man standing, wouldn’t you?',
              'Don’t lie, I’m sure you would.',
              'If you want a chance to win, tweet about My Fucking Pickle!',
              'lel, I joke... ',
              'Do you think anyone else found this easter egg?',
              'I don’t know what to say anymore...',
            ][Math.floor(totalClick / 100) % 3]
          }}
        </div>
        <div v-if="inLove">
          Wow tu as cliqué {{totalClick}} fois pour remplir la barre, félicitation, tu n'as strictement rien gagné!
        </div>
      </div>
      <div class="processing" @click.prevent.stop="click">
        <div class="processing-pickle" :style="'z-index: '+((k % 2) + 1)" v-for="(p,k) in picturesAnimated" :key="k">
          <img :src="'/images/pickles_stand/pickle_'+p+'.png'" alt="">
        </div>
      </div>
    </div>
    <div class="title-fil">
      <loader :text="'Processing '+(day.getState() - 2)+'/8'"/>
    </div>
    <div class="subtitle-fil">
      Please wait, it will take a fucking long time...
    </div>
    <div class="processing-bar">
      <div class="processing-fill" :style="'width:'+stepPct+'%'"></div>
    </div>

  </section>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  name: "Processing",
  components: {Loader},
  data:() => ({
    pictures:[1,4,11,12,7],
    picturesAnimated:[1,4,11,12,7],
    picturesOther:[2,3,6,8,9,10,5],
    interval:{},
    showDance:0,
    prevKey:-1,
    fakePct:0,
    inLove:true,
    totalClick:0,
    textKey:0,
  }),
  computed: {
    season() {
      return this.$store.getters.season;
    },
    day() {
      return this.season.getCurrentDay()
    },
    stepPct(){
      return ((this.day.getState() - 2)  / 8) * 100 + this.fakePct - 1
    }
  },
  methods:{
    click(){
      let key = this.$tools.random(0, this.pictures.length)
      while(key === this.prevKey){
        key = this.$tools.random(0, this.pictures.length)
      }
      this.prevKey = key

      this.totalClick ++;

      this.fakePct += 0.1;

      if(!this.inLove) {
        this.picturesAnimated[key] = this.picturesOther[this.$tools.random(0, this.picturesOther.length)]
        clearInterval(this.interval[key])
        this.interval[key] = setTimeout(() => {
          this.picturesAnimated[key] = this.pictures[key]
        }, 1000)
      }
    }
  },
  mounted() {
    this.$root.showDailyOpen = false
    this.$root.showDetailsDayId = -1

    this.interval2 = setInterval(() => {
      this.fakePct -= 0.05;

      if(this.stepPct > 99.3){
        this.fakePct -= 0.11
      }

      if(this.fakePct < 0){
        this.fakePct = 0
      }

      let inLove = false;

      if(this.stepPct >= 100){
        inLove = true;
      }

      if(!this.inLove && inLove){
        for(let i = 0; i < this.picturesAnimated.length;i++) {
          clearInterval(this.interval[i])
          this.picturesAnimated[i] = 6
        }
      }

      this.inLove = inLove

    },200)
  },
  unmounted() {
    clearInterval(this.interval)
    clearInterval(this.interval2)
  }
}
</script>
