<template>
  <section id='mint' class='container'>
    <div class='wanna-wrapper line-height'>
      <div class='wanna'>
        Connect your wallet
      </div>
      <div class="bottom" style="display: inline-block; z-index: 10;position: relative">
        <div class="cta" @click.prevent.stop="connection">
          Connect your wallet
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Connection",
  methods:{
    connection(){
      this.emitter.emit("web3modal:connect")
    }
  }
}
</script>
