<template>

  <Game>
    <transition :name="'route'" mode="out-in">
      <component :is="currentPage"></component>
    </transition>
  </Game>
</template>

<script>
import Game from "@/components/Game";
import Choose from "@/pages/Choose";
import DailyResult from "@/pages/DailyResult";
import FinalResult from "@/pages/FinalResult";
import Recap from "@/pages/Recap";
import Loading from "@/pages/Loading";
import NotStarted from "@/pages/NotStarted";
import Connection from "@/pages/Connection";
import Processing from "@/pages/Processing";
import Error from "@/pages/Error";

export default {
  components: {Loading, Recap, FinalResult, DailyResult, Choose, Game, NotStarted, Connection, Processing, Error},
  data:() => ({
    params:{}
  }),
  computed:{
    season(){
      return this.$store.getters.season
    },
    currentPage(){
      if(!this.$store.getters.isConnected){
        return Connection
      }
      if(!this.season.isLoaded()){
        return Loading
      }
      if(!this.season.isStateStarted()){
        return NotStarted
      }
      if(this.season.isStateDayProcessingNotFinal()){
        return Processing
      }

      if(this.$root.showDailyOpen){
        return DailyResult
      }

      if(this.season.isStateDayResult(false)){
        return DailyResult
      }
      if(this.season.isStateDayResult(true) || this.season.isStateDayProcessingFinal()){
        return FinalResult
      }
      if(this.season.isStateDayFinish(true)){
        return Recap
      }
      if(this.season.isStateDayChoose()){
        return Choose
      }
      return Error
    }
  },
  mounted() {
    this.$tools.executeWhenConnected(this, () => {
      this.$store.dispatch("UPDATE_SEASON")
    })
  },
  watch:{
    "$store.getters.contractLoaded"(value) {
      if (!value) return;
      this.$tools.executeWhenConnected(this, () => {
        this.$store.dispatch("UPDATE_SEASON")
      })
    },
  },
}
</script>
