export default {
    state: () => ({
        pictures:{}
    }),
    getters: {
        pictureLoaded: state => url => state.pictures[url],
        picture: (state, getters) => url => {
            if(!getters.pictureLoaded(url)){
                return "/images/loader_pickle.png"
            }
            return url;
        }
    },
    actions: {
        PRELOAD_PICTURE({ commit, getters }, url) {
            
            if(getters.pictureLoaded(url)){
                return;
            }
            
            const image = new Image;
            image.src = url;
            image.onload = () => {
                commit('PRELOAD_PICTURE', url)
            }
        }
    },
    mutations: {
        "PRELOAD_PICTURE": (state, url) => {
            state.pictures[url] = Date.now()
        }
    }
}