<template>

  <!-- <div class='responsive-test'></div> -->


  <div class='wrapper-gradient'>
    <div class='gradient'></div>
  </div>

  <header>
    <div class='container row'>
      <div class='logo-box'>
        <div class='logo'></div>
      </div>
      <a href='#' class='burger'><img src='/images/burger.svg' alt='burger'></a>
      <div class='nav row hidden'>
        <a href='#' class='before'>Play</a>
        <a :href='$config.rulesURL' target="_blank" class='before'>Rules</a>
        <a href='#' class='before'>My Fucking Pickle</a>
        <div class='social-wrapper row'>
          <a href='#' class='social' target='_blank'><svg class='ico'><use xlink:href='#icon-twitter'></use></svg></a>
          <a href='#' class='social' target='_blank'><svg class='ico'><use xlink:href='#icon-discord'></use></svg></a>
        </div>
        <a href='#' class='highlighted'>0x491...1f10</a>
      </div>
    </div>
  </header>


  <section class='page-type'>
    <div class='container'>
      <div class='sort'>
        <a href='/' class='cta secondary'>Back</a>
      </div>
      <div class='title-block'>
        <h1>Privacy Policy</h1>
        <p class='italic'>Last update: June 12th, 2022</p>
      </div>
      <p>In the context of your participation to the Pickle Escape contest (the “Game”), we at Miinded, a French simplified joint stock company (société par actions simplifiée) with a share capital of 40 € and registered before the Trade and Company Register of Nantes under number 907 927 461 (“Miinded” or “we”) may collect and processes your Personal Data.</p>
      <p>We value your privacy and are committed to comply with applicable data privacy laws, such as the French data privacy act n°78-17 as amended and the General Data Protection Regulation from EU Regulation 2016/679 pursuant to this Privacy Policy (the “Policy”).</p>
      <p>The Policy aims to inform you about how we use your personal data and how you may keep control over it.<br/>For any question about the Policy, please contact us by email at: <a href='mailto:myfuckingpickle@miinded.com'>myfuckingpickle@miinded.com</a>.<br/>Please read this Policy along with the Terms governing the Services available here <a href='#terms-of-use'>play.myfuckingpickle.io/terms-of-service</a>.</p>

      <section>
        <h2>1. Who collects and processes your personal data?</h2>
        <p>We act as the data controller of your personal data that we collect and process during your access and use of the Game.</p>
      </section>

      <section>
        <h2>2. For what purposes are your personal data processed?</h2>
        <p>In connection with your participation in the Game, we use your personal data to:</p>
        <ul>
          <li>allow you to register to the Game</li>
          <li>allow you to play the Game</li>
          <li>allow you to purchase Pickles and Tools during the Game</li>
          <li>manage your requests, questions and complaints and the follow-up to be given to them in relation to the operation of the Game</li>
          <li>enable you to exercise your rights relating to your personal data</li>
        </ul>
        <p>These purposes are specific and legitimate and, under no circumstances, will your personal data be further processed in a way that is incompatible with these purposes, except with your prior consent.</p>
      </section>

      <section>
        <h2>3. What are the legal bases for processing your personal data?</h2>
        <p>We process your personal data for the performance of contractual measures or legal obligations.</p>
        <div>
          <h3>(a) Performance of contract</h3>
          <p>We process your personal data on the basis of the performance of the contract to allow you to register to the Game, play the Game, purchase Pickles and Tools, and to process and respond to your requests (questions, complaints, solicitations). Where the processing of your personal data is based on the performance of the contract, the collection and processing of your personal data is necessary. If you refuse to provide us your personal data for these purposes, we will not be able to, where applicable, register you, allow you to play the Game or make purchases and/or respond to your request.</p>
        </div>
        <div>
          <h3>(b) Legal obligations</h3>
          <p>We may be required to process your personal data to comply with legal, regulatory, judicial or administrative obligations, and in particular in the context of the management of your rights relating to your personal data.</p>
        </div>
      </section>

      <section>
        <h2>4. What categories of personal data are processed?</h2>
        <p>We only process your personal data that are strictly necessary to pursue the purposes described above, which includes the following categories of data:</p>
        <ul>
          <li>your wallet(s) address and other information related to your wallet(s)</li>
          <li>information related to the in-game order you make (when you purchase Pickles and/or Tools)</li>
          <li>information related to your progress in the Game such as the number of Jar or Pickles you still have</li>
          <li>any data that you may provide to us in relation to the requests that you may make</li>
        </ul>
      </section>

      <section>
        <h2>5. Who are the recipients of the personal data processed?</h2>
        <p>Your personal data is carefully stored and only made available under strict conditions to the following people:</p>
        <ul>
          <li>members of our staff who need to access it</li>
          <li>our service providers</li>
        </ul>
      </section>

      <section>
        <h2>6. Are personal data transferred outside the European Economic Area?</h2>
        <p>We may transfer some of your personal data outside the European Economic Area.</p>
        <p>These transfers of personal data outside the European Economic Area are carried out in compliance with the applicable regulations:</p>
        <ul>
          <li>by transferring your personal data to a recipient located in a country that has received an adequacy decision from the European Commission certifying that it has an adequate level of protection for personal data</li>
          <li>by signing Standard Contractual Clauses which have been approved by the European Commission as providing an adequate level of protection for your personal data</li>
          <li>or by using any other appropriate safeguards</li>
        </ul>
      </section>

      <section>
        <h2>7. What are the retention periods for your personal data?</h2>
        <p>Your personal data is kept only for as long as is necessary for the purposes for which it was collected. The retention periods applied to data are proportionate to the purposes for which they were collected.</p>
        <p>The retention periods for your personal data are as follows:</p>
        <ul>
          <li>Allow you to register to participate in the Game, data retention periods: for the duration of the contractual relationship</li>
          <li>Allow you to play the Game, data retention periods: for the duration of the contractual relationship</li>
          <li>Allow you to make in-game purchase of Pickles and/or Tools when you request them, data retention periods: for the time necessary to complete the transaction</li>
          <li>Manage your requests, questions and complaints and the follow-up to be given to them, data retention periods: for the duration of the contractual relationship</li>
          <li>Enable you to exercise your rights relating to your personal data, data retention periods: for the time necessary to manage and process your requests</li>
        </ul>
        <p>At the end of the retention periods set above, we may archive your personal data for documentary and evidentiary purposes during the statutory limitation periods.</p>
      </section>

      <section>
        <h2>8. Your rights regarding your personal data</h2>
        <p>You may exercise your rights by writing to us and providing proof of your identity by any means. If we have reasonable doubts about your identity, we may ask you for a copy of your identity document.</p>
        <div>
          <h3>8.1. Access to your personal data</h3>
          <p>You have the right to know whether we hold any of your personal data and if so, to request access to it.</p>
        </div>
        <div>
          <h3>8.2. Rectify your personal data</h3>
          <p>You have the right to rectify or request rectification of any of your personal data which is inaccurate or incomplete.</p>
        </div>
        <div>
          <h3>8.3. Erase your personal data</h3>
          <p>Where relevant, you have the right to have your personal data be erased. This right can only be exercised in certain cases; for example, it may be personal data which is no longer necessary for the purposes for which it was collected or which has been processed unlawfully.</p>
          <p>If you rightfully exercise your right, we will proceed with the erasure of your personal data as soon as possible.</p>
          <p>The deletion is final, except in cases where processing of your personal data is necessary to comply with a legal obligation or to establish, exercise or defend legal claims.</p>
        </div>
        <div>
          <h3>8.4. Restriction of processing of your personal data</h3>
          <p>You have the right to have the processing of your personal data be restricted. This right may be exercised where you contest the accuracy of your personal data.</p>
          <p>This right does not result in any erasure and we are obliged to inform you before the relevant processing restriction is lifted.</p>
        </div>
        <div>
          <h3>8.5. Portability of your personal data</h3>
          <p>You may request to receive your Personal Data in a commonly used and machine-readable structured format for portability purposes.</p>
          <p>The right to portability of processed personal data is not a general and absolute right. Thus, this right only applies when the processing is based on your consent or on a contract and the processing is carried out by automated means.</p>
        </div>
        <div>
          <h3>8.6. Defining directives concerning the fate of your personal data after your death</h3>
          <p>You can give us instructions on what to do with your personal data after your death and how to use it.</p>
        </div>
        <div>
          <h3>8.7. Making a complaint</h3>
          <p>If, despite all the care we take to protect your personal data, you believe that your rights have not been respected, you may lodge a complaint with the competent authority (in France, the CNIL).</p>
        </div>
        <div>
          <h3>8.8. Contact</h3>
          <p>For any request regarding your personal data, please contact us: <a href='mailto:myfuckingpickle@miinded.com'>myfuckingpickle@miinded.com</a></p>
        </div>
      </section>

      <section>
        <h2>9. Changes to our Policy</h2>
        <p>In order to satisfy you as much as possible, the Game may be improved or evolved.</p>
        <p>We may have to modify the Policy from time to time, in particular to comply with any regulatory, legal, editorial or technical developments.</p>
        <p>In such a case, we will inform you by any means of this modification and the date appearing at the top of the document will be modified. We invite you to regularly refer to this page.</p>
      </section>

      <section>
        <h2>10. Blockchain</h2>
        <p>You acknowledge and agree that due to the nature of Blockchain technology, once a transaction has been processed, the associated transaction data will be irreversibly documented on the Blockchain. We hold no responsibility of any kind for any inscription of your personal data on the Blockchain.</p>
      </section>

    </div>
  </section>

  <footer>
    <div class='container'>
      <div class='spacer'></div>
      <div class='bottom row'>
        <div class='nav row'>
          <a href='#terms-of-use' class='before'>Terms of use</a>
          <a href='#privacy-policy' class='before'>Privacy Policy</a>
        </div>
        <div class='copyright row'>
          <div class='powered'>Powered by</div>
          <a href='https://www.miinded.com/' target="_blank" class='miinded'></a>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  name: "Privacy"
}
</script>

<style scoped>

</style>
