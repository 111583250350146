import {Day} from "@/class/day";
import {Final} from "@/class/final";

export class Season {
    constructor(data) {
        if (!data) return this;

        for(const key in data){
            if(key === "days"){
                const days = []
                for(const day of data[key]){
                    days.push(new Day(day))
                }
                this.days = days;
            }else if(key === "final"){
                this.final = new Final(data[key])
            }else{
                this[key] = data[key];
            }
        }

        this.loaded = true;
    }

    isStateStarted(){
        if(!this.state){
            return "season_not_started";
        }
        return this.state.state !== "season_not_started";
    }
    isStateDayProcessingNotFinal(){
        if(!this.state){
            return "";
        }
        return this.state.state === "day_processing" && this.state.param.isFinal === false;
    }
    isStateDayProcessingFinal(){
        if(!this.state){
            return "";
        }
        return this.state.state === "day_processing" && this.state.param.isFinal === true;
    }
    isStateDayResult(final){
        if(!this.state){
            return "";
        }
        return this.state.state === "daily_result" && this.state.param.isFinal === final;
    }
    isStateDayFinish(final){
        if(!this.state){
            return "";
        }
        return this.state.state === "day_finish" && this.state.param.isFinal === final;
    }
    isStateDayChoose(){
        if(!this.state){
            return "";
        }
        return this.state.state === "day_choose";
    }
    getStateDayId(){
        if(!this.state || !this.state.param){
            return -1
        }
        return this.state.param.dayId
    }
    isLoaded(){
        return this.loaded !== undefined
    }
    isPlayer(){
        return this.user.pickles > 0
    }
    getSeasonId(){
        return this.seasonId;
    }
    getTotalSupply(){
        if(!this.pickles){
            return 0;
        }
        return parseInt(this.pickles);
    }
    getTotalWallets(){
        return this.wallets || 0;
    }
    getMaxSupply(){
        return this.maxSupply || 0;
    }
    getCurrentSupply(){
        return this.currentSupply || 0;
    }
    getSupplyLeft(){
        return this.getMaxSupply() - this.getCurrentSupply();
    }
    isFinish(){
        return this.done
    }
    isFinal(){
        return this.getCurrentDay().isFinal()
    }
    getDays(){
        return this.days
    }
    getDay(dayId){
        return this.days[dayId]
    }
    getFinal(){
        if(!this.final){
            return new Final()
        }
        return this.final;
    }
    getUserData(){
        return this.user
    }
    getUserWallet(){
        return this.user.wallet
    }
    getShieldUsed(){
        if(!this.user) return false
        return this.user.shieldUsed
    }
    getUserPickles(){
        if(!this.user){
            return 0;
        }
        return this.user.pickles
    }
    getUserPicklePending(){
        if(!this.user){
            return 0;
        }
        return this.user.picklesPending
    }
    getUserBalance(){
        if(!this.user){
            return 0
        }
        return this.user.balance;
    }
    getUserMaxFree(){
        if(!this.user){
            return 0
        }
        return this.user.maxFree;
    }
    getUserPath(){
        return this.user.path;
    }
    getCurrentDay(){
        if(!this.days) {
            return new Day();
        }
        for(const day of this.days){
            if(!day.isDone() || day === this.days[this.days.length - 1]) {
                return day;
            }
        }
        return new Day();
    }
    getNextSeason(){
        if(!this.nextSeason){
            return null
        }
        return new Season(this.nextSeason);
    }
    getLastDay(){
        return this.days[this.days.length - 1]
    }
    getPrevDay(){
        if(!this.days) {
            return new Day();
        }
        let prevDay = new Day();
        for(const day of this.days){
            if(!day.isDone()) {
                return prevDay;
            }
            prevDay = day;
        }
        return prevDay;
    }
    isDead(){
        return this.user.balance <= 0 && this.getLastDay().getUserPickleAlive() - this.getLastDay().getUserPickleDead() === 0
    }
    getNextSeasonStartAt(){
        if(!this.nextSeasonStartAt){
            return new Date().toISOString()
        }
        return new Date(this.nextSeasonStartAt * 1000).toISOString()
    }

    getRecap(){
        const finalUser = this.getFinal().getUserReward();
        if(finalUser){
            return {
                isFinalist:true,
                rank:finalUser.rank,
                reward:finalUser.reward,
            }
        }else{
            return{
                isFinalist:false,
                rank: this.user.rank,
                reward: "0",
            }
        }
    }
    setCashPrice(value){
        this.cashPrice = value
    }
    getCashPrice(){
        if(!this.cashPrice){
            return 0
        }
        return this.cashPrice
    }
    setPrice(value){
        this.price = value
    }
    getPrice(){
        if(!this.price){
            return 0
        }
        return this.price
    }
    getUserProof(){
        if(!this.user) return false
        return this.user.proof
    }
    setUserBalanceMint(value){
        this.balanceMint = value
    }
    setUserBalanceFreeMint(value){
        this.balanceFreeMint = value
    }
    getUserBalanceMint(){
        return this.balanceMint
    }
    getUserBalanceFreeMint(){
        return this.balanceFreeMint
    }
    setTotalSupplyMint(value){
        this.totalSupplyMint = value
    }
    getTotalSupplyMint(){
        return this.totalSupplyMint
    }
    setSeasonStartAt(value) {
        this.seasonStartAt = Number(value) + 3600
    }
    getSeasonStartAt(){
        if(!this.seasonStartAt){
            return new Date().toISOString()
        }
        return new Date(this.seasonStartAt * 1000).toISOString()
    }
    getMintCloseAt(){
        if(!this.seasonStartAt){
            return new Date().toISOString()
        }
        return new Date((this.seasonStartAt - 3600) * 1000).toISOString()
    }
    getUserJarId(){
        if(!this.user) return false;
        return this.user.jarId;
    }
    getUserHasJar(){
        if(!this.user) return false;
        return this.user.hasJar;
    }
    getUserHasJarPending(){
        if(!this.user) return false;
        return this.user.hasJarPending;
    }
    getUserBalanceMFP(){
        if(!this.user) return 0;
        return this.user.balanceMFP || 0
    }
    getStartSeasonTime(){
        if(!this.timestamps){
            return new Date().toISOString()
        }
        return new Date(this.timestamps.startSeason * 1000).toISOString()
    }
    getEndClaimTime(){
        if(!this.timestamps){
            return new Date().toISOString()
        }
        return new Date(this.timestamps.endClaim * 1000).toISOString()
    }
    getNextSeasonTime(){
        if(!this.timestamps){
            return new Date().toISOString()
        }
        return new Date(this.timestamps.nextSeason * 1000).toISOString()
    }
    getProcessingDayTime(dayId){
        if(!this.timestamps || !this.timestamps.processingDays[dayId]){
            return new Date().toISOString()
        }
        return new Date(this.timestamps.processingDays[dayId] * 1000).toISOString()
    }
    isBeforeClaimStarted(){
        return new Date() < new Date(this.getStartSeasonTime())
    }
    isClaimStarted(){
        return new Date() > new Date(this.getStartSeasonTime())
    }
}
