import GameRouter from "./pages/GameRouter";
import HomeRouter from "@/pages/HomeRouter";
import Privacy from "@/pages/Privacy";
import Terms from "@/pages/Terms";


export default [
    {
        path:'/',
        name:'HomeRouter',
        component : HomeRouter
    },
    {
        path:'/game',
        name:'GameRouter',
        component : GameRouter
    },
    {
        path:'/privacy-policy',
        name:'Privacy',
        component : Privacy
    },
    {
        path:'/terms-of-use',
        name:'Terms',
        component : Terms
    },
]
