export default {
    // global
    "global_connected": "You are connected!",
    "global_bad_network": "You are not on the mainnet network",
    "global_error_server": "Ok, the server crashed... Don't worry, we are on it!",
    "global_error_toolate": "Too late, the paths are closed",
    "global_no_pickle": "You don't have pickle!",
    "global_no_metamask": "You need to install Metamask",
    "global_sign_error": "You need to sign to proof that's you",
    "global_way_selection": "We are saving your choice",
    "global_way_choose_valid": "Your choice has been saved",
    "global_already_selected": "This plane is already selected",
    "global_way_signing": "Awaiting signature",

    "global_mint_rules_not_ok": "You must accept the rules, I hope you have read them",
    "global_wait_mint": "Come on, don't be shy, you can validate your transaction",
    "global_minting": "Your money is being debited",
    "global_mint_success": "Great, we got your fucking money, you will receive your NFT in a few minutes",
    "global_mint_error": "Shit, something went wrong with your mint",
    "global_mint_balance_error": "You are too greedy brother, you don't have enough money!",
    "global_mint_cancel": "Seriously, did you cancel?",
    "global_claim_signing": "We need a signature at the bottom right, please",
    "global_set_pickle_signing": "We need a signature at the bottom right, please",
    "global_claim_signing_error": "You have to sign",
    "global_claim_error": "Fuck there's a fucking error: %message%",
    "global_claim_success": "Your fucking jar has been reserved, it will arrive in a few minutes",
    "global_claim_loading": "Reservation of your jar in progress...",

    "global_set_pickle_not_ok": "You didn't accept the conditions!",
    "global_bonus_error": "Fuck there's a fucking error: %message%",
    "global_bonus_signing": "We need a signature at the bottom right, please",
    "global_bonus_signing_error": "No signature, no tools",
    "global_bonus_loading": "It's loading...",
    "global_bonus_success": "Here you go, thanks for your purchase",
    "global_need_more_mfp": "You need more MFP to do this",
    "global_jar_minted_success": "Your fucking jar has arrived!",
    "global_pickle_minted_success": "Your pickles have arrived!",
}
