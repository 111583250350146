import api from "../../class/api";
import {Season} from "@/class/season";
// import {tools} from "@/utils";

export default {
    state: () => ({
        season: new Season(),
        autoRefresh:false,
        gameReady:false,
    }),
    getters: {
        season: state => state.season,
        autoRefresh: state => state.autoRefresh,
        gameReady: state => state.gameReady,
    },
    actions: {
        async UPDATE_SEASON({commit, getters, dispatch}) {

            if (getters.isLoadingBrut("UPDATE_SEASON")) {
                return;
            }

            commit("TOGGLE_LOADER", {type: "UPDATE_SEASON", toggle: true})

            let season = new Season()

            let result = await api.get("game/current/" + (getters.wallet || ""));

            if (!result.error()) {
                const seasonData = result.payload()

                season = new Season(seasonData)
            }

            commit("UPDATE_SEASON", {season})


            commit("TOGGLE_LOADER", {type: "UPDATE_SEASON", toggle: false})

            if(!getters.autoRefresh) {

                commit("AUTO_REFRESH")

                setInterval(() => {
                    dispatch("UPDATE_SEASON")
                }, 15000)
            }

        },
    },
    mutations: {
        "AUTO_REFRESH": (state) => {
            state.autoRefresh = true;
        },
        "GAME_READY": (state) => {
            state.gameReady = true;
        },
        "UPDATE_SEASON": (state, {season}) => {

            state.season = season;
        }
    }
}
