<template>

  <div class='container column'>

    <div class='selection dailyresults row'>
      <div class='left order-first column'>
        <div class='title'>
          <div class='left'>
            <h1>Pickles<br/><span class='green'>run away</span></h1>
            <h2>Final </h2>
          </div>
        </div>
        <div class='result-line' v-if="day.getUserPickleAlive() === 0">
          Spectator mode<br/>
          You are watching a hollywood movie!<br/>
          You can’t believe what your eyes and ears, the pickles fighting for their lives, giving everything! <br/>
          And this epic action music by Dj Gold Pickle!
        </div>
        <div class='result-line' v-else>
          Will you be the one pickle to escape the kitchen?!<br/>
          Fly your way on a paper plane out of this fucking hell!<br/>
          This is not the time for a sodium drink!
        </div>
        <div class="data overview-row row" style="margin-bottom: 50px;">
          <div class='overview column' style="margin-right: 20px; width: 50%">
            <div class='overview-left'></div>
            <div class='wrapper-border column'>
              <div class='top row'>
                <div class='overview-title'>Total Planes</div>
                <div class='border-top'></div>
              </div>
              <div class='list row'>
                <div class='bignumber'>{{  day.getAlive() }}
                  <span class='small'> {{ $filters.plural('plane', day.getAlive()) }}</span>
                </div>
              </div>
            </div>
            <div class='overview-right'></div>
          </div>
          <div class='overview column' v-if="day.getUserPickleAlive() > 0" style=" width: 50%">
            <div class='overview-left'></div>
            <div class='wrapper-border column'>
              <div class='top row'>
                <div class='overview-title'>My Pickles</div>
                <div class='border-top'></div>
              </div>
              <div class='list row'>
                <div class='bignumber'>{{ day.getUserPickleAlive() }} <span class='small'> left</span></div>
              </div>
            </div>
            <div class='overview-right'></div>
          </div>
        </div>

        <p v-if="!killEnd">
          <loader text="Processing"/>
        </p>

        <div class="scrollable" ref="scrollable">
          <div
              class='result-line'
              v-for="(line, k) in message"
              :key="k"
              v-html="line"
          ></div>
        </div>

        <div class='pushs row' v-if="killEnd">
          <a href="/" class='cta secondary'>
            Back to home
          </a>
          <div class='cta' @click.prevent.stop="recap" v-if="!nextDayLoader">Season recap</div>
          <div class="cta" v-else>
            <loader/>
          </div>
        </div>

      </div>
      <div class='right final end row won' v-if="killEnd">
        <div class="user-lost">
          <div>
            {{ isWinner ? 'Your' : 'The' }} <span class="green">plane #{{ final.getWinner().jarId }}</span> Escaped.<br/>
            Congratulation to {{ !isWinner ? ' to '+$filters.addressSub(final.getWinner().wallet) : '' }} and last standing pickle!
            <div class="reward green">
              <br/>{{ isWinner ? 'You' : 'He' }} win{{ isWinner ? '' : 's' }} {{ $filters.fromWei(season.getCashPrice()) }} ETH
            </div>
            <div class="small">
              It will be automatically sent to {{ isWinner ? 'your' : 'his' }} wallet within 24 hours.
            </div>
          </div>
        </div>
        <div class="image-win">
          <img src="/images/escaped.jpg" alt="">
        </div>
      </div>
      <div class='right final column won' v-if="!killEnd">
        <div class="bg"></div>
        <div class="user-lost" v-if="userLost">
          Your plane #{{ season.getUserJarId() }} Crashed.<br/>
          You loose!
        </div>
        <div class="plane" v-if="planesShow[planePointer[0]]"
             :class="{'killed':!planesShow[planePointer[0]].alive, 'in':planesShow[planePointer[0]].in}">
          <div class="anim-v">
            <div class="anim-h">
              <div class="anim-r">
                <div class="traine"></div>
                <div class="pickles">
                  <div class="pickle" v-for="(pickle,k) in planesShow[planePointer[0]].pickles" :key="k">
                    <img :src="'/images/pickle_plane/pickle_'+pickle+'.png'" alt="">
                  </div>
                </div>
                <div class="planeId">#{{ planesShow[planePointer[0]].jarId }}</div>
                <img :src='planesShow[planePointer[0]].image' class='myplane' alt=""/>
              </div>
            </div>
          </div>
        </div>

        <div class="planefront" v-if="planesShow[planePointer[1]]"
             :class="{'killed':!planesShow[planePointer[1]].alive, 'in':planesShow[planePointer[1]].in}">
          <div class="anim-v">
            <div class="anim-h">
              <div class="anim-r">
                <div class="pickles">
                  <div class="pickle" v-for="(pickle,k) in planesShow[planePointer[1]].pickles" :key="k">
                    <img :src="'/images/pickle_plane/pickle_'+pickle+'.png'" alt="">
                  </div>
                </div>
                <div class="planeId">#{{ planesShow[planePointer[1]].jarId }}</div>
                <img :src='planesShow[planePointer[1]].image' class='myplane' alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div class="planeback" v-if="planesShow[planePointer[2]]"
             :class="{'killed':!planesShow[planePointer[2]].alive, 'in':planesShow[planePointer[2]].in}">
          <div class="anim-v">
            <div class="anim-h">
              <div class="anim-r">
                <div class="pickles">
                  <div class="pickle" v-for="(pickle,k) in planesShow[planePointer[2]].pickles" :key="k">
                    <img :src="'/images/pickle_plane/pickle_'+pickle+'.png'" alt="">
                  </div>
                </div>
                <div class="planeId">#{{ planesShow[planePointer[2]].jarId }}</div>
                <img :src='planesShow[planePointer[2]].image' class='myplane' alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import api from "@/class/api";
import Loader from "@/components/Loader";

export default {
  components: {Loader},
  data: () => ({
    killKey: -1,
    killEnd: false,
    nextDayLoader: false,
    planes: null,
    message: [],
    userLost:false,

    planesShow: [],
    randPlanes: [],
    planePointer: [-1, -1, -1],
  }),
  methods: {
    async recap() {

      this.nextDayLoader = true

      const result = await api.post("game/user/" + this.$store.getters.wallet + "/valid-death-screen", {
        seasonId: this.season.getSeasonId(),
        dayId: this.day.getDayId()
      })

      if (result.error()) {
        this.nextDayLoader = false
        return this.emitter.emit("alert:error", "global_error_server")
      }

      this.$store.dispatch("UPDATE_SEASON")
    },

    setPickleInPlane(nb) {
      const pickles = []
      for (let i = 0; i < nb; i++) {
        pickles.push(this.$tools.random(3, 12));
      }
      return pickles
    },

    loadPlanes() {
      this.planes = []
      for (const plane of this.day.getJarIds()) {

        const nb = this.$tools.clamp(plane.pickles, 0, 4);

        const planeWayId = plane.wayId === 0 ? this.$tools.random(1,24) : plane.wayId
        this.planes.push({
          image: "/images/plane/plane_" + planeWayId + ".png",
          nb,
          pickles: this.setPickleInPlane(nb),
          alive: true,
          jarId: plane.jarId,
        })
      }

      this.getRandomPlanes();

      let first = 0
      if(this.day.getUserSelectWayId() > -1){
        first = this.planesShow.findIndex(plane => parseInt(plane.jarId) === parseInt(this.season.getUserJarId()))
      }

      this.planePointer[0] = first
      this.planePointer[1] = 1
      this.planePointer[2] = 2
    },

    refreshPickleInPlane() {
      const rand = this.$tools.random(1,4);
      console.log(rand)
      if(this.planesShow[this.planePointer[0]] && this.planesShow[this.planePointer[0]].alive && this.planesShow[this.planePointer[0]].pickles.length > 0 && rand === 1) {
        this.planesShow[this.planePointer[0]].pickles[this.$tools.random(0, this.planesShow[this.planePointer[0]].pickles.length)] = this.$tools.random(3, 12)
      }

      if(this.planesShow[this.planePointer[1]] && this.planesShow[this.planePointer[1]].alive && this.planesShow[this.planePointer[1]].pickles.length > 0 && rand === 2) {
        this.planesShow[this.planePointer[1]].pickles[this.$tools.random(0, this.planesShow[this.planePointer[1]].pickles.length)] = this.$tools.random(3, 12)
      }

      if(this.planesShow[this.planePointer[2]] && this.planesShow[this.planePointer[2]].alive && this.planesShow[this.planePointer[2]].pickles.length > 0 && rand === 3) {
        this.planesShow[this.planePointer[2]].pickles[this.$tools.random(0, this.planesShow[this.planePointer[2]].pickles.length)] = this.$tools.random(3, 12)
      }
    },

    getRandomPlanes() {

      this.randPlanes = this.$tools.copy(this.planes)
      this.randPlanes.sort(() => {
        return Math.random() < 0.5 ? -1 : 1
      })

      this.planesShow = []
      for (let i = 0; i < this.randPlanes.length; i++) {
        this.planesShow.push(this.randPlanes[i])
      }

    },

    replaceRandomPlanes(key) {
      // si c'est le joueur qui vient de perdre on lui remet pas son avion en palce
      if(key === 0 && this.day.getUserSelectWayId() > -1){
        this.userLost = true;
        return;
      }

      let newKey = -1;
      try {
        for (let i = 0; i < this.planesShow.length; i++) {
          if (
              this.planesShow[i].jarId !== this.planesShow[this.planePointer[0]].jarId &&
              this.planesShow[i].jarId !== this.planesShow[this.planePointer[1]].jarId &&
              this.planesShow[i].jarId !== this.planesShow[this.planePointer[2]].jarId &&
              this.planesShow[i].alive
          ) {
            newKey = i;
            break;
          }
        }
      }catch (e){
        return;
      }

      this.planePointer[key] = newKey;

      if (this.planesShow[this.planePointer[key]]) {
        this.planesShow[this.planePointer[key]].in = true;

        setTimeout(() => {
          this.planesShow[this.planePointer[key]].in = false;
        }, 2000)
      }

    },

    killShowPlane(jarId) {

      const key = this.planesShow.findIndex(plane => parseInt(plane.jarId) === parseInt(jarId))
      this.planesShow[key].alive = false;

      let pointer = -1;
      for(let i = 0; i < 3; i++){
        if(key === this.planePointer[i]){
          pointer = i;
          break;
        }
      }

      if(pointer === -1){
        return;
      }

      this.planesShow[key].pickles = []
      for (let i = 0; i < this.planesShow[key].nb; i++) {
        this.planesShow[key].pickles.push(this.$tools.random(2, 3));
      }

      setTimeout(() => {
        this.replaceRandomPlanes(pointer);
      }, 3000)
    },

    killPlane() {

      const killed = this.kill

      if (killed.length === 0) {
        return;
      }

      this.killKey++;

      // tant qu'on a des gens a tuer
      if (this.killKey < killed.length - 1) {

        const jarId = killed[this.killKey].jarId
        console.log(killed, this.killKey, jarId, this.planes)
        const data = this.planes.find(plane => parseInt(plane.jarId) === parseInt(jarId))

        const me = data.jarId === this.season.getUserJarId() ? "me" : "";
        this.message.push(
            "<span class='wallet " + me + "'>" + (me ? 'Your ' : '') + "Plane</span>#" + data.jarId + " " +
            "<span class='red'>crashed</span>"
        )

        this.killShowPlane(jarId)
        this.planes = this.planes.filter(plane => parseInt(plane.jarId) !== parseInt(jarId))

      }

      // s'il n'en reste plus qu'un
      if (this.killKey === killed.length - 1) {
        clearInterval(this.intervalKill)
        clearInterval(this.intervalPickle)
        this.killEnd = true;

        const jarId = killed[this.killKey].jarId
        const winner = this.planes.find(plane => parseInt(plane.jarId) === parseInt(jarId))

        const me = winner.wallet === this.$store.getters.wallet ? "me" : "";

        this.message.push("")
        this.message.push(
            "<span class='wallet " + me + "'>" + (me ? 'Your ' : '') + "Plane </span>#" + winner.jarId + "</span> " +
            "<span class='green'>Escaped!</span>"
        )
      }

      setTimeout(() => {
        this.$refs.scrollable.scrollTop = 99999999
      }, 5)
    }
  },
  watch: {
    kill(value, previous) {
      if (value.length !== previous.length) {
        this.loadPlanes();
      }
    }
  },
  computed: {
    season() {
      return this.$store.getters.season;
    },
    day() {
      // console.log(this.$store.getters.season.getCurrentDay())
      return this.$store.getters.season.getCurrentDay();
    },
    final() {
      return this.season.getFinal()
    },
    kill() {
      return this.final.getKill();
    },
    isWinner(){
      return this.final.getWinner().jarId === this.season.getUserJarId()
    }
  },
  mounted() {
    this.loadPlanes();

    this.intervalKill = setInterval(() => {

      this.killPlane();

    }, 5000)

    this.intervalPickle = setInterval(() => {

      this.refreshPickleInPlane()

    }, 2000)

  },
  unmounted() {
    clearInterval(this.intervalKill)
    clearInterval(this.intervalPickle)
  }
}
</script>
