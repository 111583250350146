import { Clicker } from "@/class/clicker";

export default {
    state: () => ({
        clicker: new Clicker(),
        autoRefresh: false
    }),
    getters: {

    },
    actions: {

    },
    mutations: {
    }
}
