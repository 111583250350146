import contract from "./class/contract";
import _alerts from "./data/_texts";

export const tools = {
    random(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    },
    copy(obj) {
        return JSON.parse(JSON.stringify(obj))
    },
    setupPage(classe) {
        document.body.className = "";
        document.body.classList.add(classe);
        window.scrollTo(0, 0)
    },
    sleep(delay) {
        return new Promise(resolve => setTimeout(resolve, delay));
    },
    round(number, x) {
        return Math.round(number * x) / x
    },
    toFloat(cost) {
        return parseFloat(contract.fromWei(cost, "ether"))
    },
    toWei(cost) {
        return contract.toWei(cost)
    },
    executeWhenConnected($vue, callback) {

        if (!$vue.$store.getters.isConnected) {
            let timeout = setTimeout(callback, 1000)
            $vue.emitter.on("web3modal:connect:success", () => {
                clearTimeout(timeout)
                callback()
                $vue.emitter.off("web3modal:connect:success")
            })

        } else {
            callback()
        }
    },
    arrayDefault(count, value) {
        const array = []
        for (let i = 0; i < count; i++) {
            array.push(value)
        }
        return array;
    },
    textsFormat(data) {
        let slug = data;
        let param = {}
        if (Array.isArray(data)) {
            slug = data[0];
            param = data[1];
        }
        let msg = _alerts[slug]
        if (msg === undefined) {
            return slug
        }
        for (const [key, value] of Object.entries(param)) {
            msg = msg.replace("%" + key + "%", value)
        }
        return msg
    },
    clamp (num, min, max){
        return Math.min(Math.max(num, min), max);
    },
    commify(number, separator = ",") {
        var parts = number.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, separator) + (decimalPart ? "." + decimalPart : "");
    }
}
export const openUrl = (url, target) => {

    if (target === "_blank") {
        return window.open(url)
    }

    document.location.href = url;
}
