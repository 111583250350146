<template>

  <div class='overlay row popup-booster' @click.prevent.stop.self="$emit('close')">
    <div class='container' @click.prevent.stop.self="$emit('close')">
      <div class='modal booster large column' v-if="!chooseWay">
        <h5>Tools shop</h5>
        <p>Tools in Pickle Escape can be handy in some situations, use them wisely!</p>
        <div class='boosts column'>
          <div class='boost-detail row' v-if="day.getDayId() === 0">
            <img src='/images/boost/boost_10.jpg' alt='Boost 10'>
            <div class='right column'>
              <div class='title row'>
                <div class='name'>More pickles</div>
                <div class='when'>Available the first day only.</div>
              </div>
              <div class='description'>
                More pickles for more brain power! Be careful though, too many pickles can lead to a worse result.
              </div>
              <div class='bot full row'>
                <div class='quantity row'>
                  <div
                      class='btn row lock'
                      @mousedown="lessIntStart"
                      @mouseup="lessIntStop"
                      @mouseleave="lessIntStop"
                  >
                    -
                  </div>
                  <div class='number'>
                    {{ totalCount }}
                  </div>
                  <div
                      class='btn row'
                      @mousedown="moreIntStart"
                      @mouseup="moreIntStop"
                      @mouseleave="moreIntStop"
                  >
                    +
                  </div>
                </div>
                <div class='price'>{{ $filters.fromWei(pricePickle) }} ETH</div>
                <div class='cta' @click.prevent.stop="buyBonus(10)">Add</div>
              </div>
            </div>
          </div>
          <div class='boost-detail row' :class="{'disable':day.getBonus(1) || season.getShieldUsed()}" v-if="day.getDayId() <= 1">
            <img src='/images/boost/boost_1.jpg' alt='Boost 1'>
            <div class='right column'>
              <div class='title row'>
                <div class='name'>God Shield</div>
                <div class='when'>1 max. per season - Available day 1 and 2</div>
              </div>
              <div class='description'>
                Tonight, nothing can happen to you. You are sure to escape without losing any pickles.
              </div>
              <div class='bot row'>
                <div class='price'>0.015 ETH</div>
                <div class='cta' @click.prevent.stop="buyBonus(1, 0.015)">Activate</div>
              </div>
            </div>
          </div>
          <div class='boost-detail row' :class="{'disable':day.getBonus(3)}" v-if="day.getDayId() <= 5">
            <img src='/images/boost/boost_3.jpg' alt='Boost 3'>
            <div class='right column'>
              <div class='title row'>
                <div class='name'>Spy a path</div>
                <div class='when'>1 use allowed per day - available from day 1 to 5</div>
              </div>
              <div class='description'>Display the number of pickles in the selected path at a given moment.</div>
              <div class='bot row'>
                <div class='price'>0.015 ETH</div>
                <div class='cta' @click.prevent.stop="selectWay">Buy & Use</div>
              </div>
            </div>
          </div>
          <div class='cta tertiary' @click.prevent.stop.self="$emit('close')">Close</div>
        </div>
      </div>

      <div class='modal booster large column selection' v-else>
        <h5>Spy a path</h5>
        <p>
          Choose a path, it will reveal how many pickles are in this path at this exact moment.<br/>
          This tool can be used once per day
        </p>
        <div
            class='paths-list'
        >
          <div class='wrapper-select row'>
            <div
                v-for="(data) in ways"
                :key="data.wayId"
                class='path'
                @click="buyBonus(3, 0.015, data.wayId)"
            >
              <img :src='$store.getters.picture(day.getPictureURL(data.pictureId))' alt=''>
            </div>
            <div class='cta tertiary' @click.prevent.stop.self="$emit('close')">Close</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contract from "@/class/contract";
import api from "@/class/api";
import {Day} from "@/class/day";
import {Season} from "@/class/season";

export default {
  name: "Booster",
  props:{
    day: {type:Object, default:() => new Day()},
    season: {type:Object, default:() => new Season()},
  },
  data:() => ({
    totalCount:1,
    chooseWay:false
  }),
  methods:{
    async more() {
      this.totalCount++;
      if (this.totalCount >= this.maxPickle) {
        this.totalCount = this.maxPickle
      }

    },
    moreIntStart() {
      this.more()

      this.moreInt = setTimeout(() => {
        this.moreIntStart()

        this.delayCount -= 2;
        if (this.delayCount <= 1) {
          this.delayCount = 1
        }

      }, this.delayCount)
    },
    moreIntStop() {
      clearInterval(this.moreInt)
      this.delayCount = 100
    },
    async less() {

      this.totalCount--;
      if (this.totalCount < 1) {
        this.totalCount = 1
      }
    },
    lessIntStart() {
      this.less()

      this.moreInt = setTimeout(() => {
        this.lessIntStart()

        this.delayCount -= 2;
        if (this.delayCount <= 1) {
          this.delayCount = 1
        }

      }, this.delayCount)
    },
    lessIntStop() {
      clearInterval(this.moreInt)
      this.delayCount = 100
    },

    selectWay(){
      this.chooseWay = true
    },

    async buyBonus(bonusId, price, data){

      this.emitter.emit("alert:loader", "global_bonus_signing")

      const count = bonusId !== 10 ? 1 : this.totalCount
      const value = bonusId !== 10 ? contract.toWei(price) : this.pricePickle;

      const jarId = this.$store.getters.season.getUserJarId()

      const message = {
        owner: this.$store.getters.wallet,
        jarId,
        bonusId,
        count,
        data:data
      }

      let signature;
      try {
        signature = await contract.createSignature(message, this.$store.getters.wallet)
      } catch (e) {
        this.emitter.emit("alert:loader:hide")
        return this.emitter.emit("alert:error", "global_bonus_signing_error")
      }

      this.emitter.emit("alert:loader:hide")
      this.emitter.emit("alert:loader", "global_bonus_loading")

      const result = await api.post("game/buy", {
        message, signature
      })

      if (result.error()) {
        this.emitter.emit("alert:loader:hide")
        return this.emitter.emit("alert:error", ["global_bonus_error", {message: result.errorMsg()}])
      }

      console.log({
        wallet: this.$store.getters.wallet,
        jarId,
        bonusId,
        count,
        maxTimestamp :result.payload().maxTimestamp,
        value
      })

      await contract.c("PickleEscape").run(
          "buyItem",
          [jarId, bonusId, count, result.payload().maxTimestamp, result.payload().signature],
          this.$store.getters.wallet,
          value,
          {
            valid:() => {
              this.emitter.emit("alert:loader:hide")
              this.emitter.emit("alert:loader", "global_bonus_loading")
            },
            error:(e) => {
              this.emitter.emit("alert:loader:hide")
              this.emitter.emit("alert:error", ["global_bonus_error", {message:e.message}])
              this.chooseWay = false
            },
            success: async () => {
              await this.$store.dispatch("UPDATE_SEASON")
              this.chooseWay = false

              this.emitter.emit("alert:loader:hide")
              this.emitter.emit("alert:success", "global_bonus_success")
            },
          }
      )

    }
  },
  computed:{
    pricePickle(){
      return contract.toWei(this.totalCount * 0.005)
    },

    ways(){

      const pictures = this.day.getPictures()
      const ways = []
      for(let wayId = 0;wayId < pictures.length; wayId++){
        ways.push({wayId:wayId, pictureId:pictures[wayId]});
      }

      return ways
    },
  }
}
</script>
