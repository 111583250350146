import axios from "axios";
import { envs } from "@/config";

export default {
    baseURL: envs.BACKEND_URI,
    async run(method, url, data, options) {
        let response = new apiResponse()
        let res = null;
        try {
            let _url = url.match(/http?/) ? url : this.baseURL + "/" + url

            switch (method) {
                
                case 'get' :
                default :
                    res = await axios[method](_url, options);
                    break;
                case 'post' :
                    res = await axios[method](_url, data, options);
                    break;
            }
            

        } catch (e) {
            response.setError(e || 'server');
            return response;
        }
        response.setData(res)

        return response;

    },
    get(url, options) {
        return this.run("get", url, null, options);
    },
    post(url, data, options = {}) {
        return this.run("post", url, data, options);
    }
}

class apiResponse {
    constructor() {
        this._error = null;
    }
    setData(res) {
        this._response = res
        this._data = res.data

        if (this._data.error) {
            this.setError({ response: this._response })
        }
    }
    setError(error) {
        this._error = error

        if (this._error.response && this._error.response.data) {
            this._data = this._error.response.data
        }
    }
    error() {
        return this._error !== null
    }
    errorMsg() {
        if (this._error.response && this._error.response.data.message) {
            return this._error.response.data.message
        }
        return this._error
    }
    data() {
        return this._data
    }
    payload() {
        return this._data.payload
    }
}
