export const flexibleGetEnv = (key) => {
    const windowAny = window;

    if (windowAny.windowEnv && windowAny.windowEnv[key]) {
        return windowAny.windowEnv[key]
    }

    return process.env[key];
}

export const envs = {
    NODE_ENV: flexibleGetEnv("NODE_ENV"),
    NETWORK_ID: flexibleGetEnv("VUE_APP_NETWORK_ID"),
    BACKEND_URI: flexibleGetEnv("VUE_APP_BACKEND_URI"),
    BACKEND_URI_CLICKER: flexibleGetEnv("VUE_APP_BACKEND_URI_CLICKER"),
    BLOCKCHAIN_ADDRESS_ETH: flexibleGetEnv("VUE_APP_BLOCKCHAIN_ADDRESS"),
    WALLET_CONNECT_PUBLIC_KEY: flexibleGetEnv("VUE_APP_WALLET_CONNECT_PUBLIC_KEY"),
    CONTRACT_ADDRESS_PICKLEESCAPE: flexibleGetEnv("VUE_APP_CONTRACT_ADDRESS_PICKLEESCAPE"),
};

export default {
    envs: envs,
    dataCached: 10 * 60 * 1000, // 10min de cache
    openSeaJarCollection : "https://opensea.io",

    chestDateEnd:"2022-06-17T23:00:00.000Z",
    // chestDateEnd:"2022-06-15T14:15:00.000Z",
    // chestDateEnd:new Date((new Date()).getTime() + 5_000).toISOString(),

    rulesURL: "https://miinded.notion.site/Rules-EN-471396f92ea642b5be042831eb50ac85"
}
