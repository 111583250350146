
export default {
    state: () => ({
        // wallet: "0x62241e1DA5Ea9Bc0Da436DCC0CA5C214200e1027",
        wallet: null,
        walletLoaded: false,

    }),
    getters: {
        wallet: state => state.wallet,
        isConnected: state => state.wallet !== null,
        walletLoaded: state => state.walletLoaded,
        connected: state => state.wallet !== null,
    },
    actions: {
        SET_USER_WALLET({commit}, wallet) {
            commit("SET_USER_WALLET", wallet)
        },
    },
    mutations: {
        "SET_USER_WALLET": (state, wallet) => {
            state.wallet = wallet
        },
    }
}
