<template>

  <div class='container column'>

    <div class='selection recap-selection row'>
      <div class='left order-first column'>
        <div class='title'>
          <div class='left'>
            <h1>Recap<br/><span class='green'>Season #{{ season.getSeasonId() }}</span></h1>
          </div>
        </div>
        <div class='result-line f22' v-if="recap">
          You finished <span class='green'>#{{recap.rank}}</span>
<!--          <span v-if="recap.isFinalist">and won <span class='green'>{{ $filters.fromWei(final.getUserReward().reward) }} eth</span>!</span>-->
        </div>
<!--        <div class='result-line claim' v-if="recap.isFinalist">-->
<!--          You can only claim on sundays-->
<!--        </div>-->
        <div class='result-line claim' v-else>
          All your pickles are dead
        </div>

        <div class='overview-row final row'>
          <div class='overview column'>
            <div class='overview-left'></div>
            <div class='wrapper-border column'>
              <div class='top row'>
                <div class='overview-title'>My Pickles</div>
                <div class='border-top'></div>
              </div>
              <div class='list row'>
                <div class='bignumber'>{{ season.getUserPickles() }}
                  <span class='small'> {{ $filters.plural('pickle', day.getAlive()) }}</span>
                </div>
              </div>
            </div>
            <div class='overview-right'></div>
          </div>
        </div>

        <div class='recap-actions'>
          <div class='overview-row row'>
            <div class='overview death column'>
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>Daily Overview</div>
                  <div class='border-top'></div>
                </div>
                <div class='list row'>
                  <div
                      class='day'
                      v-for="(type, _dayId) in season.getUserPath()"
                      :key="_dayId"
                      :class="[type, {'selected': _dayId === day.getDayId()}]"
                      @click.prevent.stop="changeDay(_dayId, type)"
                  ></div>
                </div>
              </div>
              <div class='overview-right'></div>
            </div>
            <div class='overview column' v-if="final.getUserReward()">
              <div class='overview-left'></div>
              <div class='wrapper-border column'>
                <div class='top row'>
                  <div class='overview-title'>Claimable</div>
                  <div class='border-top'></div>
                </div>
                <div class='bignumber'>{{ $filters.fromWei(final.getUserReward().reward) }} <span
                    class='small'>ETH</span></div>
              </div>
              <div class='overview-right'></div>
            </div>
          </div>

<!--          <div class='pushs row'>-->
<!--            <div class='cta secondary'>Show off on Twitter</div>-->
<!--            <div class='cta' v-if="final.getUserReward()">Claim ETH</div>-->
<!--            <a href="/" class='cta secondary'>-->
<!--              Back to home-->
<!--            </a>-->
<!--          </div>-->
        </div>

<!--        <div class='overview full column'>-->
<!--          <div class='overview-left'></div>-->
<!--          <div class='wrapper-border column'>-->
<!--            <div class='top row'>-->
<!--              <div class='overview-title'>Next season</div>-->
<!--              <div class='border-top'></div>-->
<!--            </div>-->
<!--            <div class='content column'>-->
<!--              <h4 v-if="!isNextSeasonStarted">-->
<!--                <span class='green'>-->
<!--                  Season #{{ season.getSeasonId() + 1 }}-->
<!--                </span>-->
<!--                starts in-->
<!--                <span class='green'>-->
<!--                  <countdown-->
<!--                      :surchargeClass="'countdown-small'"-->
<!--                      :end="season.getNextSeasonTime()"-->
<!--                      :after="{hours:'h ', minutes:'m ', seconds:'s'}"-->
<!--                      @end="nextSeasonStarted = true"-->
<!--                  />-->
<!--                </span>-->
<!--              </h4>-->
<!--              <h4 v-if="isNextSeasonStarted">-->
<!--                <span class='green'>-->
<!--                  Season #{{ season.getSeasonId() + 1 }}-->
<!--                </span>-->
<!--                started-->
<!--              </h4>-->
<!--              <div class='txt' v-if="isNextSeasonStarted">You can now claim your jar/pickle for the next season.</div>-->
<!--              <div class='txt' v-else>You will be able to claim your jar/pickle for the new season</div>-->
<!--              <a href="/" class='cta' v-if="isNextSeasonStarted">Let's Go</a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class='overview-right'></div>-->
<!--        </div>-->
      </div>

      <div class='right recap column'>
        <div class='top-global row'>
          <div class='global-left'>
            <div class='result-line f22'>Global Infos.</div>
            <div class='global'>
              <div class='result-line'><span class='grey'> {{ $filters.number(season.getTotalSupply()) }}</span> pickles
              </div>
              <div class='result-line'><span class='grey'>{{ $filters.number(season.getTotalWallets()) }}</span> players
              </div>
            </div>
          </div>
          <div class='overview column'>
            <div class='overview-left'></div>
            <div class='wrapper-border column'>
              <div class='top row'>
                <div class='overview-title'>Cashprize</div>
                <div class='border-top'></div>
              </div>
              <div class='bignumber'>{{ $filters.fromWei(final.getCashPrice()) }} <span class='small'>ETH</span></div>
            </div>
            <div class='overview-right'></div>
          </div>
        </div>
        <div class='overview full column'>
          <div class='overview-left'></div>
          <div class='wrapper-border column'>
            <div class='top row'>
              <div class='overview-title'>Final Recap</div>
              <div class='border-top'></div>
            </div>
            <div class='content column'>
              <div
                  class='recap-line row'
                  v-for="(line, k) in killList"
                  :key="k"
              >
                <div class='result-line row' v-html="line.html"></div>
                <div class='death row'>
                  <div
                      class='day-final'
                      v-for="(type, _dayId) in line.path"
                      :key="_dayId"
                      :class="[type, {'selected': _dayId === day.getDayId()}]"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class='overview-right'></div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Countdown from "@/components/Countdown";

export default {
  components: {Countdown},
  data: () => ({
    nextSeasonStarted:false
  }),
  methods: {
    changeDay(dayId, type) {
      if (type === "notyet") {
        return
      }
      if (dayId === 5) {
        this.$root.showDetailsDayId = -1
        this.$root.showDailyOpen = false;
        this.$root.finalRecap = this.season.getSeasonId()
        return;
      }

      this.$root.finalRecap = -1
      this.$root.showDetailsDayId = dayId
      this.$root.showDailyOpen = true;
    },
  },
  computed: {
    season() {
      return this.$store.getters.season;
    },
    day() {
      return this.season.getDay(5)
    },
    final() {
      return this.season.getFinal()
    },
    recap(){
      return this.season.getRecap()
    },
    killList() {
      const message = []

      const rewards = this.$tools.copy(this.final.getReward())
      rewards.reverse()

      for (const reward of rewards) {

        const me = reward.wallet === this.$store.getters.wallet ? "me" : "";

        message.push({
          html: "<div class='l1'><span class='green'>#" + reward.rank + "</span>: <span class='wallet " + me + "'>" + this.$filters.addressSub(reward.wallet) + "</span></div>" +
              (reward.rank === 1 ? "<div class='l2'>wins <span class='green'>" + this.$filters.fromWei(this.season.getCashPrice()) + " ETH</span></div>" : ''),
          path:reward.path
        })
      }
      return message;
    },
    isNextSeasonStarted(){
      return this.nextSeasonStarted || this.season.getFinal().getNextSeasonState() > 0
    }
  },
}
</script>
