const sounds = {
    files: [],
    audios: {},
    muted: false,
    volume: 0.1,
    init(_files) {
        this.files = _files;
        _files.forEach(file => {
            this.preload(file);
        })
    },
    preload(file) {
        this.audios[file] = new Audio("/sounds/" + file);
        this.audios[file].preload = "auto"
    },
    play(file) {
        if(!this.audios[file]){
            console.warn("Sound file: "+file+" not founded");
            return;
        }
        this.audios[file].volume = this.muted ? 0 : this.volume;
        this.audios[file].currentTime = 0
        this.audios[file].play()
    },
    mute(toggle){
        this.muted = toggle;
        this.files.forEach(file => {
            this.audios[file].volume = this.muted ? 0 : this.volume;
        })
    },
    stop(file) {
        this.audios[file].pause();
    },
}

export default sounds;
