<template>
  <div class='wrapper-gradient'>
    <div class='gradient'></div>
  </div>

  <header>
    <div class='container row'>
      <div class='logo-box'>
        <div class='logo'></div>
      </div>
      <div @click.prevent.stop="menuBurger = !menuBurger" class='burger'><img src='/images/burger.svg' alt='burger'></div>
      <div class='nav row' :class="{hidden:!menuBurger}">
        <a href='/' class='before'>Home</a>
        <a :href='$config.rulesURL' target="_blank" class='before'>Rules</a>
        <div class='social-wrapper row'>
          <a href='https://twitter.com/MyFuckingPickle' class='social' target='_blank'><svg class='ico'><use xlink:href='#icon-twitter'></use></svg></a>
          <a href='https://discord.com/invite/5r5Kt6s5v3' class='social' target='_blank'><svg class='ico'><use xlink:href='#icon-discord'></use></svg></a>
        </div>
        <a href='#' class='highlighted' v-if="$store.getters.isConnected" @mouseleave="connectText = false" @mouseenter="connectText = true" @click.prevent.stop="disconnect">
          {{ connectText ? 'Disconnect' :  $filters.addressSub($store.getters.wallet)}}
        </a>
        <a href='#' class='highlighted' v-else @click="emitter.emit('web3modal:connect')">
          Connect your wallet
        </a>
      </div>
    </div>
  </header>

  <section id='game'>
    <slot/>
  </section>

  <footer>
    <div class='container'>
      <div class='spacer'></div>
      <div class='bottom row'>
        <div class='nav row'>
          <a href='#terms-of-use' class='before'>Terms of use</a>
          <a href='#privacy-policy' class='before'>Privacy Policy</a>
        </div>
        <div class='copyright row'>
          <div class='powered'>Powered by</div>
          <a href='https://www.miinded.com/' target="_blank" class='miinded'></a>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  name: "Game",
  data:() => ({
    connectText:false,
    menuBurger:false,
  }),
  methods:{
    disconnect(){
      window.web3Modal.clearCachedProvider();
      document.location.reload()
    }
  }
}
</script>
