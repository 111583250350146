<template>
  <div id="chest" :class="{ended}">
    <div class='container column'>

      <img src='/images/chest/chest.png' class='chest'>
      <div class='intro' v-if="!ended">You were waiting for it...</div>

      <countdown-chest
          :end="$config.chestDateEnd"
          @end="end"
      />
    </div>
  </div>
</template>

<script>
import sounds from "@/class/sounds";
import CountdownChest from "@/components/Countdown-chest";

export default {
  name: "Chest",
  components: {CountdownChest},
  data:() => ({
    ended:false
  }),
  created() {
    sounds.init(['beep.mp3', 'click.mp3', 'click-nice.mp3', 'jar.mp3', 'dring.mp3'])
  },
  methods: {
    end() {
      sounds.play("dring.mp3")
      this.ended = true;

      setTimeout(() => {
        this.$root.chestEnd = true
        sounds.stop("dring.mp3")
      }, 5000)
    }
  },
  mounted() {
    const imagesClicker = [
      "/images/clicker/background.jpg",
      "/images/clicker/chest.png",
      "/images/clicker/pickle_01_normal.png",
      "/images/clicker/pickle_01_hitting.png",
      "/images/clicker/pickle_02_normal.png",
      "/images/clicker/pickle_02_hitting.png",
      "/images/clicker/pickle_03_normal.png",
      "/images/clicker/pickle_03_hitting.png",
      "/images/clicker/pickle_04_normal.png",
      "/images/clicker/pickle_04_hitting.png",
    ]
    for(const img of imagesClicker) {
      this.$store.dispatch("PRELOAD_PICTURE", img)
    }
  }
}
</script>
