<template>
  <section id='mint' class='container'>
    <div class='wanna-wrapper'>
      <div class='wanna'>
        <loader text="Loading"/>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from "@/components/Loader";
export default {
  name: "Loading",
  components: {Loader}
}
</script>
