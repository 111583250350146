<template>

  <!-- <div class='responsive-test'></div> -->


  <div class='wrapper-gradient'>
    <div class='gradient'></div>
  </div>

  <header>
    <div class='container row'>
      <div class='logo-box'>
        <div class='logo'></div>
      </div>
      <a href='#' class='burger'><img src='/images/burger.svg' alt='burger'></a>
      <div class='nav row hidden'>
        <a href='#' class='before'>Play</a>
        <a :href='$config.rulesURL' target="_blank" class='before'>Rules</a>
        <a href='#' class='before'>My Fucking Pickle</a>
        <div class='social-wrapper row'>
          <a href='#' class='social' target='_blank'><svg class='ico'><use xlink:href='#icon-twitter'></use></svg></a>
          <a href='#' class='social' target='_blank'><svg class='ico'><use xlink:href='#icon-discord'></use></svg></a>
        </div>
        <a href='#' class='highlighted'>0x491...1f10</a>
      </div>
    </div>
  </header>


  <section class='page-type'>
    <div class='container'>
      <div class='sort'>
        <a href='/' class='cta secondary'>Back</a>
      </div>
      <div class='title-block'>
        <h1>Terms of Use</h1>
        <p class='italic'>Last update: June 13th, 2022</p>
      </div>
      <p>These Terms of Use (“Terms”) is a legally binding agreement between Miinded, a French simplified joint stock company (société par actions simplifiée) with a share capital of 40 € and registered before the Trade and Company Register of Nantes under number 907 927 461 (“Miinded” or “we”, “us”) and you as a natural individual (“you”) with respect to your participation in the Pickle Escape contest (“Game”).</p>
      <p>By participating in the Game you represent that you have read and agree to the Terms, and that you comply with it.</p>

      <section>
        <h2>1. Scope of the Game</h2>
        <p>The Game is a weekly online virtual community contest (each a “Season”) on Polygon Blockchain, available from <a href='https://www.myfuckingpickle.io' target="_blank">https://www.myfuckingpickle.io</a> (the “Website”) organized by us. The aim of the Game is to have your Pickle escape from the kitchen after a Pickle party without being killed and be the last surviving Pickle of the Season to be rewarded.</p>
        <p>Your participation in the Game is free even thus optional tools are available to you upon purchase.</p>
      </section>

      <section>
        <h2>2. Participation in the Game</h2>
        <div>
          <h3>2.1. Duration</h3>
          <p>The Game is seasonal, each Season last for seven (7) days. The first Season starts on June 18, 2022, upon expiration of a Season a new Season will automatically begin unless the Game is terminated or stopped by us at any time without prior notice.</p>
        </div>
        <div>
          <h3>2.2. Residence</h3>
          <p>You can participate in the Game whatever your country of residence, so long as you do not reside in a country that is under U.S. government, United Kingdom, European Union (including its Member States) or United Nations embargo or that you or your financial institution is not subject to international financial sanction by US, United Kingdom, European Union (including its Member States) or United Nations regulations.</p>
        </div>
        <div>
          <h3>2.3. Number of participants</h3>
          <p>Each Season is limited in terms of number of participants, due to technical constraint. Only a single participation per individual is allowed by Season as determined by your jar (the “Jar”), but there is no restriction as to the number of Season(s) you may participate in. You must not multiplicate your participation in a Season through the use of any automatic system; if you fail to comply with the foregoing we will automatically terminate your participation in the Season and you will be excluded from participating in any other Seasons of the Game.</p>
        </div>
        <div>
          <h3>2.4. Requirements</h3>
          <p>To participate in the Game, you must have:</p>
          <ul>
            <li>reached the age of majority in your country of residence at the time of participation in a Season</li>
            <li>recent and up to date hardware, software and internet connection that support the technology to access the Game</li>
            <li>your own wallet to be used in conjunction with the Game</li>
            <li>a Jar with one or several Pickles in it</li>
            <li>not be excluded from a previous Season or the Game by us</li>
            <li>not be involved in the preparation or operation of the Game or be a relative of any of the foregoing</li>
          </ul>
          <p>Your participation to the Game may require that we process your Personal Data, such processing will be conducted in accordance with our privacy policy available at <a href='#privacy-policy'>play.myfuckingpickle.io/privacy-policy</a>.</p>
        </div>
        <div>
          <h3>2.5. Commitment</h3>
          <p>To participate in the Game, you must not:</p>
          <ul>
            <li>use the Game for purposes that are illegal or prohibited by the Terms or by applicable law</li>
            <li>use a third party's account to access the Game</li>
            <li>attempt to circumvent any security measures to limit, restrict or prohibit access to any area or feature of the Game</li>
            <li>upload viruses or other malicious code, or otherwise compromise the security of the Game</li>
            <li>use a robot or other computerized means or interface to access the Game</li>
            <li>use or develop third-party applications that interact with the Game or other participants' content or data</li>
            <li>use the Game in a manner that could damage, disable, overburden, or impair the Game or that could interfere with the proper functioning of the Game.</li>
          </ul>
          <p>Failure to comply with any of the above conditions may result in your disqualification from the current Season and your exclusion to the participation of any future Seasons.</p>
        </div>
        <div>
          <h3>2.6. Jar</h3>
          <p>A “Jar” is a NFT, it is a digital representation of a jar created by us with an underlying token that can be used on the Polygon blockchain. A Jar is tradable among participants on third-party NFT marketplaces. Any sales or exchanges on third-party marketplaces is out of the scope of these Terms and will be subject to the terms and conditions provided by such marketplace.</p>
          <p>You may claim a Jar free of charge (gasless):</p>
          <ul>
            <li>if you have any Fucking Pickle NFT(s) in your wallet, pursuant to an airdrop from us. Only one (1) Jar is available whatever the number of Fucking Pickle NFT you have in your wallet, or</li>
            <li>at each Season claim/mint phase. Jars are subject to registration and available stock between one thousand (1,000) and two thousand (2,000) per Season, the stock of Jar is renewed at the beginning of each Season. Only one (1) Jar per wallet can be claimed.</li>
          </ul>
        </div>
        <div>
          <h3>2.7. Pickle</h3>
          <p>A “Pickle” is part of the Metadata of a Jar, it is not a NFT nor a digital asset. Pickles are not tradable or transferable, only a Jar within the meaning of these Terms is tradable. If a Jar is traded, the Pickles in it are reset.</p>
          <p>One (1) Pickle corresponds to one (1) life for a Season of the Game.</p>
          <p>Pickles are available:</p>
          <div>
            <h4>(a) for claim if you have any Fucking Pickle NFT(s) collectible in your wallet</h4>
            <p>Each empty Jar entitles you to claim free of charge one (1) Pickle to play the Game. This a one-time claim per Season you participate in and provided that your Jar is empty at the beginning of the relevant Season.</p>
          </div>
          <div>
            <h4>(b) for claim if you have any Jar NFT collectible in your wallet</h4>
            <p>If you have any Fucking Pickle NFT collectible in your wallet, you will be entitled to claim free of charge four (4) Pickles plus Pickles on a 1:1 basis (e.g. if you have two (2) Fucking Pickles in your wallet, you may claim six (6) Pickles) to play the Game. This a one-time claim per Season you participate in. If you claim Pickles but do not participate in a Season, the Pickles will remain available for the next Season (without any right of additional claim). If your Pickles have been used during a Season and died during that Season, your Jar will be filled up again accordingly at the beginning of the next Season you participate in.</p>
          </div>
          <div>
            <h4>(c) from optional purchase</h4>
            <p>On day 2 of each Season (not before or after), you may purchase as many Pickles as you want in accordance with the Terms.</p>
          </div>
          <p>Provided that you own several Jars, Pickles are transferable from one Jar to another prior to your participation in a Season. Once you choose to participate in the Game with a Jar, the number of Pickles held in such Jar are used for the Season without right to retrieve them during such Season.</p>
          <p>Any transfer of a Jar from a participant to another during a Season will automatically terminate your participation in the Game for that Season (and the purchaser of the Jar will not be able to participate in such Season of the Game with that purchased Jar) and the Pickles available in the Jar will be reset.</p>
        </div>
        <div>
          <h3>2.8. Optional purchase</h3>
          <p>The Game includes the following optional in-game purchase</p>
          <div>
            <h4>(a) Pickle</h4>
            <p>One or several Pickles can be purchased from us during day 2 of each Season only, at the price set in the Game. Any Pickle purchased must be used during the Season it was purchased without any right to be saved for later Season(s).</p>
          </div>
          <div>
            <h4>(b) Tool</h4>
            <p>A “Tool” can be purchased from us during the Season to grant an additional power to your Pickle. Available Tools are a shield (which is a one-day protection for all Pickles in a Jar from being killed) or a vision (which allows you to see once and at a designed time the number of other Pickles on a single path).</p>
            <p>Tools are limited for purchase and available for a limited time during each Season: </p>
            <ul>
              <li>up to one (1) “shield” per Season per participant to be purchased and used in day 2 or 3 of the Season</li>
              <li>up to two (2) “spy” per Season per participant to be purchased and used from day 2 to day 6 of the Season (maximum one per day)</li>
            </ul>
          </div>
          <p>Any purchase you make in-game is a firm and final commitment to pay us the applicable price and payable via your wallet to which the Jar belongs to. Your payment will automatically be processed by a smart contract.</p>
        </div>
      </section>

      <section>
        <h2>3. Rules of the Game</h2>
        <p>Each Season of the Game is sequenced as follows.</p>
        <div>
          <h3>Phase 1: Claim / Mint (24 hours from the start of the Season)</h3>
          <p>During day 1 of the Season, you can register to participate in the Game and claim your Jar and Pickles and/or purchase optional Pickles. You can also manage the number of Pickles in your Jar.</p>
        </div>
        <div>
          <h3>Phase 2: play the Game (6 days from the end of Phase 1, including final of the Season)</h3>
          <p>From day 2 to day 7 of the Season and provided that you still have living Pickles in your Jar, you must choose each day one (1) path out of the six available for your Pickles to escape. All Pickles from the same Jar must take the same path, no split of Pickles among the paths is allowed.</p>
          <p>Your daily choice of the path must be made no later than [Heure et fuseau horaire à compléter], if you do not make a choice on time, your Pickle(s) will be automatically sent to the most crowded path. By the end of each day of the Season, the total number of Pickles on each path will be calculated. Pickles on the less crowded path will be saved while Pickles will be randomly killed on the most crowded path (in proportion of the crowd). Only the surviving Pickles will be allowed to continue the Game for the subsequent day of the Season.</p>
          <p>During this phase, you may purchase optional Pickles and Tools as set before.</p>
          <p>The final of the Season takes place on day 7 with Pickles remaining alive. Each participant must chose an option out of the 6 options available and all Pickles but one will be killed randomly. The choice of option will be made by each participant, each option chosen by one participant being no longer available to the others. The last participant to choose will therefore have to settle for the last available option.</p>
          <p>The last surviving Pickle will be the winner of the Game for the Season.</p>
          <p>At any stage of the Season, you may quit the Game. Your termination of the Game will be effective at the end of the day once the killing of Pickles has occurred. You Jar and the remaining surviving Pickles in it, if any, will no longer participate in the Season.</p>
          <p>Throughout the Season, we reserve the right to exclude any participant who does not comply with the Terms or whose behavior (including on social networks related to its participation to the Game) could be considered as fraudulent or in violation of applicable laws.</p>
          <p>Any violation of the Terms will result in the disqualification of the participant from the Game (the current Season and any further Seasons) and all rights or privileged (including non-used Pickles and Tools) will be immediately terminated.</p>
        </div>
        <div>
          <h3>Phase 3: winning Pickle’s announcement and reward</h3>
          <p>The winner who holds the last surviving Pickle will be informed on our Website.</p>
          <p>His wallet address will also be shown on the Website and on Discord.</p>
          <p>The winner will be granted a reward that will be automatically transferred to the participant’s wallet where the Jar of the surviving Pickle belongs to. Reward will under no circumstances be delivered to another address (only one winner per Season).</p>
        </div>
      </section>

      <section>
        <h2>4. Reward</h2>
        <p>The reward will be a cash prize awarded in Ether which under no circumstances be offset against legal tenders. The amount of the cash prize will be announced at the beginning of each Season but could be increased during the course of it.</p>
      </section>

      <section>
        <h2>5. Rights over the Game</h2>
        <div>
          <h3>5.1. Our rights over the Game</h3>
          <p>We and our licensors are and will remain owners of all the material and intellectual property rights attached to the Website and the Game, including notably its content, information, design, logos, text, graphics, images, icons, data, software, database, algorithms, scrips, trademark. The Terms do not grant you any rights whatsoever, except a limited license. When accessing the Game, you undertake not to infringe directly or indirectly our property rights or those of our licensors.</p>
        </div>
        <div>
          <h3>5.2. Your rights over the Game</h3>
          <p>Subject to your compliance with the terms of the Terms, we grant you, for the duration of the Season, a personal, non-exclusive, non-transferable and non-sublicensable license to access and use the Game, including use and display your Pickle in the Game, for personal use to the exclusion of any commercial use. We reserve all rights not expressly granted to you.</p>
          <p>You must not reproduce, represent, use, modify, adapt, translate, transform, communicate, distribute or make available to third parties, extract, download, store or create derivative works from all or part of the Game without our prior written consent.</p>
        </div>
        <div>
          <h3>5.3. Jar NFT</h3>
          <p>Upon receipt (or purchase if available from third parties) of a Jar, you become the owner of the NFT and have the right to freely dispose of your NFT: sale or transfer, etc. through third-party marketplaces.</p>
        </div>
      </section>

      <section>
        <h2>6. Availability of the Game</h2>
        <p>We will try our best to ensure the accessibility and availability of the Game, provided that you comply with the Terms. However, we cannot guarantee that the Game will be uninterrupted, error-free or free from malware or other harmful components.</p>
        <p>We reserve the right to suspend or restrict access to the Game, in particular in order to comply with legal requirements or in application of the Terms.</p>
        <p>Despite our efforts, please note that interruptions or suspensions may occur at any time due to the inherent limitations of the Internet, the blockchain protocol or any other technology use to operate the Game. </p>
      </section>

      <section>
        <h2>7. Disclaimer</h2>
        <p>The Game is provided by us to you “as is” and “as available” basis without any warranty of any kind from us. Subject to applicable law, we expressly disclaim any and all warranty or conditions. By participating in the Game, you expressly agree to bear all risks inherent to the access to and use of the Game.</p>
      </section>

      <section>
        <h2>8. Liability</h2>
        <div>
          <h3>8.1. Our liability</h3>
          <p>Our liability may not be sought for any indirect damages, including without limitation, loss of income, profits, opportunity, data, goodwill or reputation.</p>
          <p>We are only bound to provide our best efforts with regard to the operation of the Game, subject to applicable law our liability for direct damages shall not exceed in aggregate, per Season you participate in, the greater of (a) the total amount in Ether paid to us for in-game purchase during the Season giving rise to the claim or (b) the equivalent in Ether of $100.</p>
        </div>
        <div>
          <h3>8.2. Your liability</h3>
          <p>You may be held liable towards us for any breach of these Terms. You are responsible for your use of the Game. It is your responsibility to take all appropriate steps to protect your equipment and the information stored on your equipment, including any wallet you own.</p>
        </div>
      </section>

      <section>
        <h2>9. Applicable law and jurisdiction</h2>
        <p>The Terms are governed by French law. Any dispute between you and us must first try to be sorted out amicably, to that end please contact us at <a href='mailto:myfuckingpickle@miinded.com'>myfuckingpickle@miinded.com</a> and provide us contact details as well as any information to allow us to appreciate your claim. </p>
        <p>Besides, if you are a participant residing in the European Union, you may also contact the online dispute resolution service of the European Commission at the following address: ec.europa.eu/consumers/odr.</p>
        <p>If we cannot find a common ground, you may refer the dispute to the competent court within the jurisdiction of the French court of Appeal of Nantes, unless other court is competent in accordance with overriding mandatory provisions applicable in your country of residence.</p>
        <p>The Terms do not affect any mandatory or statutory rights you may have as a consumer in your country of residence.</p>
      </section>

      <section>
        <h2>10. Modification</h2>
        <p>We reserve the right to modify or amend these Terms at any time. If we make any material changes, we will notify you the amendments in advance and changes will only be effective as from the start of a new Season. If you do not want to agree to any updated terms, you should discontinue your participation in any further Season of the Game.  Your participation in a Season following a change of the Terms shall constitute your acceptance of the revised terms.</p>
      </section>

      <section>
        <h2>11. Miscellaneous</h2>
        <p>Any failure from us to enforce our rights under the Terms shall not be deemed to be a waiver.</p>
        <p>We reserve the right to assign or to transfer our rights and obligations under the Terms to any third party; upon transfer Miinded will be released from its obligations and liabilities. </p>
        <p>We reserve the right to delegate all or part of our obligations to any third-party service providers appointed at our discretion. </p>
        <p>If any provision of the Terms is determined to be void, inapplicable or unenforceable by virtue of law or by any final court ruling, the remainder of the Terms will be in full force and effect.</p>
        <p>You expressly acknowledge and agree, as a convention of proof, that the information collected by us concerning the use of the Game will constitute valid evidence and will be admissible and enforceable between you and us.</p>
        <p>The Terms together with our privacy policy set the entire agreement between you and us in relation to the Game we provide, they supersede and replace any prior agreement.</p>
      </section>

      <section>
        <h2>12. Smart contracts</h2>
        <p>To operate the Game, we rely on the following smartcontracts:</p>
        <ul>
          <li>Game Manager (Polygon): To manage the game. <a href='https://etherscan.io/address/0x1d1cA09497e36546151bdA9D62052015049DcAB9#code' target="_blank">Check the smart contract</a></li>
          <li>Pickle Jar (Polygon): Collection of jars and airdrops. <a href='https://etherscan.io/address/0x564Ca0980ef9F1598aC85930424F874C244fF0dF#code' target="_blank">Check the smart contract</a></li>
          <li>Pickle Escape Game (Polygon): Game, contains all the data of the game: random, days, seasons, deaths, paths and the final. <a href='https://etherscan.io/address/0x5dC9EccD3953808d129874410665aF9Cdd1b4433#code' target="_blank">Check the smart contract</a></li>
          <li>Pickle Escape (Ethereum): Purchase tools in game and transfer rewards . <a href='https://etherscan.io/address/0x564ca0980ef9f1598ac85930424f874c244ff0df#code' target="_blank">Check the smart contract</a></li>
        </ul>

      </section>

    </div>
  </section>

  <footer>
    <div class='container'>
      <div class='spacer'></div>
      <div class='bottom row'>
        <div class='nav row'>
          <a href='#terms-of-use' class='before'>Terms of use</a>
          <a href='#privacy-policy' class='before'>Privacy Policy</a>
        </div>
        <div class='copyright row'>
          <div class='powered'>Powered by</div>
          <a href='https://www.miinded.com/' target="_blank" class='miinded'></a>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  name: "Terms"
}
</script>

<style scoped>

</style>
