<template>
  <component :is="currentPage" v-if="loader"></component>
</template>

<script>
import Public from "@/pages/Public";
import Clicker from "@/components/Clicker";
import Chest from "@/pages/Chest";
import Movie from "@/pages/Movie";
import api from "@/class/api";

export default {
  name: "HomeRouter",
  components:{Public, Clicker, Chest, Movie},
  data: () => ({
    clickerActive:false,
    loader:false
  }),
  computed:{
    currentPage(){

      if(new Date(this.$config.chestDateEnd) > new Date() && !this.$root.chestEnd) {
        return Chest
      }

      if(!this.$root.clickerEnd && this.clickerActive){
        return Clicker
      }

      if(this.$root.openingChest && this.clickerActive){
        return Movie
      }

      this.$store.commit('GAME_READY')

      return Public
    }
  },
  async mounted() {
    const result = await api.get("pickle/clicker");
    if(result.error()){
      return;
    }
    this.clickerActive = result.payload()
    this.loader = true;
  },
  watch:{
    "$store.getters.contractLoaded"(value) {
      if (!value) return;
      this.$tools.executeWhenConnected(this, () => {
        this.$store.dispatch("UPDATE_SEASON")
      })
    },
  },
}
</script>
