<template>
    <div class="loader">
        {{ loadText }} <span>{{points}}</span>
    </div>
</template>

<script>
export default {
    name: "Loader",
    props:{
        text:String,
    },
    data(){
        return {
            points:""
        }
    },
    computed:{
        loadText(){
            if(!this.text || this.text === '') return "Loading"
            return this.text;
        }
    },
    mounted(){
        this.int = setInterval(() => {
            this.points += ".";
            if(this.points.length === 4){
                this.points = "";
            }
        },500)
    },
    unmounted(){
        clearInterval(this.int)
    }
}
</script>
<style scoped lang="scss">
    .loader{
        span{
            display: inline-block;
            text-align: left;
            vertical-align: top;
        }
    }
</style>