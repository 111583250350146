export class Way {
    constructor(data) {
        if (!data) return this;

        for (const key in data) {
            this[key] = data[key];
        }
    }

    getWayId(){
        return this.wayId;
    }
    getBalance(){
        return this.pickles
    }
    getKilled(){
        return this.killed
    }
}