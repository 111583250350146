<template>
  <div class="content">
    <p>
      To play the game you must own a jar.<br/>
      The jar is claimable for free & 100% gasless.<br/>
      You can play the game as long as you hold a jar.
    </p>
    <p class='text-center bold'>
      <a :href='$config.rulesURL' target='_blank' style='color:black'>Read the fucking rules</a>
    </p>
    <div class='center' style="margin-bottom: 30px;">
      <p>
        <label><input type='checkbox' v-model='checked'/> I have read the rules and I agree to the <a href="#terms-of-use" target="_blank">terms of use</a> and <a href="#privacy-policy" target="_blank">privacy policy</a></label>
      </p>
    </div>
  </div>
</template>

<script>
import config from "@/config";

export default {
  name: "popup-mint",
  data: () => ({
    checked:false,
    openSeaJarCollection: config.openSeaJarCollection
  }),
  watch: {
    checked() {
      this.$emit('valid', this.checked)
    }
  },
}
</script>
