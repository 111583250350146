<template>
  <div class="value">
    {{ $tools.commify(count) }}
  </div>
</template>

<script>
export default {
  name: "counter",
  props:{
    current: {type:Number, default:100000000},
    duration:{type:Number, default: 5000},
    update:{type:Number, default: 0}
  },
  data:() => ({
    count:0,
    marge:1000,
    fps:30
  }),
  methods:{
    show(current){
      this.clear()
      if(this.count < current){
        this.count = current
      }

      const before = this.count;
      const delay = this.duration + this.marge
      const updateFrame = delay / this.fps
      const step = (before - current) / updateFrame

      let currentFrame = 0

      this.interval = setInterval(() => {
        this.count = Math.floor(before - (step * currentFrame))
        currentFrame ++;

        if(currentFrame > updateFrame){
          this.clear()
        }
      }, 1000 / this.fps)
    },
    clear(){
      clearTimeout(this.interval)
    }
  },
  watch:{
    current(value){
      this.show(value)
    },
  },
  mounted() {

  },
  unmounted() {
    this.clear()
  }
}
</script>
