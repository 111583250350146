<template>
  <div class='path-stats' :class="{winner}">
    <div class="your-choice" v-if="selected">
      Your Choice
    </div>
    <img :src='$store.getters.picture(day.getWayPictureURL(way.getWayId(), !winner))'>
    <div class='bottom'>
      <div class='percent'>{{ getWayBalance }} ({{ getPctWayBalance }}%)</div>
      <div class='dead' v-if="winner">Winners</div>
      <div class='dead' v-else>-{{ getWayKilled }} {{ $filters.plural('pickle', getWayKilled) }}</div>
      <div class='picto'></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PathStats",
  props:{
    day: {},
    way: {},
    winner: {type:Boolean, default:false},
    selected: {type:Boolean, default:false},
  },
  computed:{
    getWayBalance(){
      return this.way.getBalance()
    },
    getWayKilled(){
      return this.way.getKilled()
    },
    getPctWayBalance(){
      return (this.way.getBalance() / this.day.getAlive() * 100).toFixed(2)
    },
  }
}
</script>
