export class Final {
    constructor(data) {
        if (!data) return this;

        for (const key in data) {
            this[key] = data[key];
        }
    }

    getKill(){
        if(!this.rewards){
            return []
        }
        return this.rewards
    }
    getReward(){
        return this.rewards;
    }
    getWinner(){
        if(!this.rewards){
            return {}
        }
        return this.rewards[this.rewards.length - 1]
    }
    getUserReward(){
        return this.userReward
    }
    getCashPrice(){
        return this.cashPrice
    }
    getNextSeasonState(){
        if(!this.nextSeason){
            return 0
        }
        return parseInt(this.nextSeason);
    }
}
